declare var gtag: Function;

import { Injectable } from "@angular/core";
import { SettingsProvider } from "./settings.provider";
import { Router, NavigationEnd } from "@angular/router";

@Injectable()
export class AnalyticsService {

  private isIE = false;

  constructor(private router: Router, private settingsProvider: SettingsProvider) {
    this.isIE = /MSIE|Trident/.test(window.navigator.userAgent); // Detect IE browser
  }

  public event(eventName: string, params: {}) {
    gtag("event", eventName, params);
  }

  public init() {
    this.listenForRouteChanges();

    try {

      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=" + this.settingsProvider.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag("js", new Date());
        gtag("config", "` + this.settingsProvider.googleAnalyticsKey + `", {"anonymize_ip": true, "send_page_view": false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error("Error appending google analytics");
      console.error(ex);
    }
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag("config", this.settingsProvider.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
        });

        if (this.isIE) {
          // Accessbility JAWS bug
          const element = document.getElementById("mygov-logo-img");
          setTimeout(() => {
            element.setAttribute("tabindex", "-1");
            element.focus();
          }, 100);
        }
      }
    });
  }
}
