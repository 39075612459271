import { Injectable } from "@angular/core";
import { Religion } from "./religion.enum";

export interface ReligionQuestion {
    religion: string;
    religionOther: string;
}

@Injectable({
    providedIn: "root",
})
export class ReligionQuestionService {
    private _religionQuestion: ReligionQuestion;

    constructor() {}

    get religionQuestion() {
        return this._religionQuestion;
    }

    updateReligionQuestion(formObj: ReligionQuestion): void {
        switch (formObj.religion) {
            case Religion.None: {
                this._religionQuestion.religion = Religion.None;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.RomanCatholic: {
                this._religionQuestion.religion = Religion.RomanCatholic;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.ChurchOfScotland: {
                this._religionQuestion.religion = Religion.ChurchOfScotland;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.OtherChristian: {
                this._religionQuestion.religion = Religion.OtherChristian;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Muslim: {
                this._religionQuestion.religion = Religion.Muslim;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Buddhist: {
                this._religionQuestion.religion = Religion.Buddhist;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Jewish: {
                this._religionQuestion.religion = Religion.Jewish;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Pagan: {
                this._religionQuestion.religion = Religion.Pagan;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Sikh: {
                this._religionQuestion.religion = Religion.Sikh;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Hindu: {
                this._religionQuestion.religion = Religion.Hindu;
                this._religionQuestion.religionOther = "";

                break;
            }
            case Religion.Other: {
                this._religionQuestion.religion = Religion.Other;
                this._religionQuestion.religionOther = formObj.religionOther;

                break;
            }
            case Religion.PreferNotToSay: {
                this._religionQuestion.religion = Religion.PreferNotToSay;
                this._religionQuestion.religionOther = "";

                break;
            }
        }
    }

    initializeReligionQuestion(): void {
        this._religionQuestion = {
            religion: "",
            religionOther: "",
        };
    }
}
