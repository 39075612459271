import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormArray,
    FormControl,
} from "@angular/forms";
import { routesNames } from "../routes.names";
import { Router } from "@angular/router";
import { CustomValidator } from "../shared/custom.validator";
import { UploadDocumentsService } from "./upload-documents.service";
import { Title } from "@angular/platform-browser";
import { SettingsProvider } from "../core/settings.provider";
import { ApplicationInfoService } from "../application-info/application-info.service";
import { AnalyticsService } from "../core/analytics.service";
import { Observable, Subscription, fromEvent } from "rxjs";
import { validationMessages } from "../shared/validation-messages";
import { ReasonForSendingService } from "../reason-for-sending/reason-for-sending.service";
import { find } from "rxjs/operators";
import { EvidenceRequest } from "./evidence-request";
import { PersonRefService } from "../person-ref/person-ref.service";

@Component({
    selector: "app-upload-documents",
    templateUrl: "./upload-documents.component.html",
})
export class UploadDocumentsComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];

    uploadDocumentForm: FormGroup;
    isSubmitted = false;
    helpTextFlag = false;
    gHelpTextFlag = false;
    apiLoading = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private uploadDocumentsService: UploadDocumentsService,
        private titleService: Title,
        private applicationInfoService: ApplicationInfoService,
        private settingsProvider: SettingsProvider,
        private analytics: AnalyticsService,
        private reasonForSending: ReasonForSendingService,
        private personRefService: PersonRefService
    ) {}

    get documents(): FormArray {
        return this.uploadDocumentForm.get("documents") as FormArray;
    }

    setAsTouched(group: FormGroup | FormArray) {
        group.markAsTouched();
        for (let i in group.controls) {
            if (group.controls[i] instanceof FormControl) {
                group.controls[i].markAsTouched();
            } else {
                this.setAsTouched(group.controls[i]);
            }
        }
    }

    ngOnInit() {
        this.uploadDocumentForm = this.fb.group({
            googleRecaptcha: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
        });
        // @Todo make the change here for allowing the combination of document types, when CIC application is being made, leave normal intact.

        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, "online");
        this.offlineEvent = fromEvent(window, "offline");

        this.subscriptions.push(
            this.onlineEvent.subscribe((e) => {
                this.uploadDocumentForm.patchValue({ online: "online" });
            })
        );

        this.subscriptions.push(
            this.offlineEvent.subscribe((e) => {
                this.uploadDocumentForm.patchValue({ online: "" });
            })
        );
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
    }

    uploadDocumentSubmit() {
        if (this.uploadDocumentForm.valid) {
            this.uploadDocumentsService
                .postDocuments(this.uploadDocumentForm.value)
                .subscribe(
                    (response) => {
                        this.router.navigate(
                            [routesNames.DOC_UPLOAD_CONFIRMATION],
                            {
                                queryParams: {
                                    success: true,
                                    referenceNumber: response["envelopeID"],
                                },
                            }
                        );
                        this.apiLoading = false;
                    },
                    (error) => {
                        if (
                            error.hasOwnProperty("statusCode") &&
                            error.statusCode === 0
                        ) {
                            console.log(error);
                            alert(validationMessages["online"]["required"]);
                        } else {
                            this.router.navigate(
                                [routesNames.DOC_UPLOAD_CONFIRMATION],
                                {
                                    queryParams: {
                                        success: false,
                                        statusCode: error.statusCode,
                                        errorMessage: error.errorMessage,
                                    },
                                }
                            );
                        }
                        this.apiLoading = false;
                    }
                );
        } else {
            this.isSubmitted = true;
            this.uploadDocumentForm.markAllAsTouched();
            this.setAsTouched(this.uploadDocumentForm);

            this.displayMessage = CustomValidator.processMessages(
                this.uploadDocumentForm
            );

            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    buildDocument(): FormGroup {
        return this.fb.group({
            file: [
                null,
                {
                    validators: [
                        Validators.required,
                        CustomValidator.fileValidator,
                    ],
                },
            ],

            documentDescription: [
                "",
                {
                    validators: [Validators.maxLength(100)],
                    updateOn: "blur",
                },
            ],
        });
    }

    addDocument(): void {
        if (this.documents.length < 20) {
            this.documents.push(this.buildDocument());
            setTimeout(() => {
                const el = document.getElementById(
                    `fileId${this.documents.length - 1}`
                );
                el.focus();
            });
        }
    }

    removeDocument(index: number): void {
        if (this.documents.length > 1) {
            this.documents.removeAt(index);
        }
    }

    goBack(): void {
        this.router
            .navigate([routesNames.SERVICE_QUESTION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.SERVICE_QUESTION}:back-click`,
                        {
                            event_category: routesNames.SERVICE_QUESTION,
                            event_label: routesNames.APPLICATION_INFO,
                        }
                    );
                }
            });
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all subscriptions to avoid memory leak
         */
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
