import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Age } from "./Age.enum";
import { AgeQuestionService } from "./age-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { AnalyticsService } from "../core/analytics.service";

@Component({
    selector: "app-age-question",
    templateUrl: "./age-question.component.html",
})
export class AgeQuestionComponent implements OnInit, AfterViewInit {
    public AgeEnum = Age;
    ageQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private ageQuestionService: AgeQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.ageQuestionForm = this.fb.group({
            age: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
        });

        this.ageQuestionForm.patchValue(this.ageQuestionService.ageQuestion);
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.ageQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    ageQuestionSubmit(): void {
        if (this.ageQuestionForm.valid) {
            this.saveToStore(this.ageQuestionForm.value);
            this.router.navigate([routesNames.ETHNIC_QUESTION]);
        } else {
            this.isSubmitted = true;
            this.ageQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.ageQuestionForm
            );

            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.ageQuestionService.updateAgeQuestion(value);
    }

    goBack(): void {
        this.router.navigate([routesNames.PERSON_REF]).then((res: boolean) => {
            if (res) {
                this.analytics.event(
                    `Evidence Submission Form | ${routesNames.AGE_QUESTION}:back-click`,
                    {
                        event_category: routesNames.APPLICATION_INFO,
                        event_label: routesNames.EVIDENCE_SUBMISSION,
                    }
                );
            }
        });
    }
}
