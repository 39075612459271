import { Injectable } from "@angular/core";
import { ConditionSub } from "./condition-sub.enum";

export interface ConditionSubQuestion {
    conditionSubVision: boolean;
    conditionSubHearing: boolean;
    conditionSubMobility: boolean;

    conditionSubDexterity: boolean;
    conditionSubLearning: boolean;
    conditionSubMemory: boolean;
    conditionSubStamina: boolean;
    conditionSubSocially: boolean;
    conditionSubNone: boolean;

    conditionSubOther: boolean;
    conditionSubPreferNotToSay: boolean;
    conditionSubOtherText: string;
}

@Injectable({
    providedIn: "root",
})
export class ConditionSubQuestionService {
    private _conditionSubQuestion: ConditionSubQuestion;

    constructor() {}

    get conditionSubQuestion() {
        return this._conditionSubQuestion;
    }

    updateConditionSubQuestion(formObj: any): void {
        var change = false;
        var val_change = false;
        var non = false;
        if (
            this._conditionSubQuestion.conditionSubVision !=
                formObj.conditionSubVision ||
            this._conditionSubQuestion.conditionSubHearing !=
                formObj.conditionSubHearing ||
            this._conditionSubQuestion.conditionSubMobility !=
                formObj.conditionSubMobility ||
            this._conditionSubQuestion.conditionSubDexterity !=
                formObj.conditionSubDexterity ||
            this._conditionSubQuestion.conditionSubLearning !=
                formObj.conditionSubLearning ||
            this._conditionSubQuestion.conditionSubMemory !=
                formObj.conditionSubMemory ||
            this._conditionSubQuestion.conditionSubStamina !=
                formObj.conditionSubStamina ||
            this._conditionSubQuestion.conditionSubOther !=
                formObj.conditionSubOther ||
            this._conditionSubQuestion.conditionSubSocially !=
                formObj.conditionSubSocially
        ) {
            val_change = true;
            if (this._conditionSubQuestion.conditionSubNone) {
                change = true;
            }

            if (
                this._conditionSubQuestion.conditionSubVision !=
                formObj.conditionSubVision
            ) {
                this._conditionSubQuestion.conditionSubVision =
                    formObj.conditionSubVision;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubHearing !=
                formObj.conditionSubHearing
            ) {
                this._conditionSubQuestion.conditionSubHearing =
                    formObj.conditionSubHearing;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubMobility !=
                formObj.conditionSubMobility
            ) {
                this._conditionSubQuestion.conditionSubMobility =
                    formObj.conditionSubMobility;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubDexterity !=
                formObj.conditionSubDexterity
            ) {
                this._conditionSubQuestion.conditionSubDexterity =
                    formObj.conditionSubDexterity;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubLearning !=
                formObj.conditionSubLearning
            ) {
                this._conditionSubQuestion.conditionSubLearning =
                    formObj.conditionSubLearning;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubMemory !=
                formObj.conditionSubMemory
            ) {
                this._conditionSubQuestion.conditionSubMemory =
                    formObj.conditionSubMemory;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubStamina !=
                formObj.conditionSubStamina
            ) {
                this._conditionSubQuestion.conditionSubStamina =
                    formObj.conditionSubStamina;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }
            if (
                this._conditionSubQuestion.conditionSubSocially !=
                formObj.conditionSubSocially
            ) {
                this._conditionSubQuestion.conditionSubSocially =
                    formObj.conditionSubSocially;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }

            if (
                this._conditionSubQuestion.conditionSubOther !=
                formObj.conditionSubOther
            ) {
                this._conditionSubQuestion.conditionSubOther =
                    formObj.conditionSubOther;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }
        } else {
            if (
                this._conditionSubQuestion.conditionSubNone !=
                    formObj.conditionSubNone &&
                formObj.conditionSubNone
            ) {
                this._conditionSubQuestion.conditionSubNone = true;
                this._conditionSubQuestion.conditionSubVision = false;
                this._conditionSubQuestion.conditionSubHearing = false;
                this._conditionSubQuestion.conditionSubMobility = false;
                this._conditionSubQuestion.conditionSubDexterity = false;
                this._conditionSubQuestion.conditionSubLearning = false;
                this._conditionSubQuestion.conditionSubMemory = false;
                this._conditionSubQuestion.conditionSubStamina = false;
                this._conditionSubQuestion.conditionSubSocially = false;

                this._conditionSubQuestion.conditionSubOther = false;
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
                non = true;
            } else {
                this._conditionSubQuestion.conditionSubNone = false;
            }

            if (
                this._conditionSubQuestion.conditionSubPreferNotToSay !=
                    formObj.conditionSubPreferNotToSay &&
                formObj.conditionSubPreferNotToSay
            ) {
                this._conditionSubQuestion.conditionSubPreferNotToSay = true;
                this._conditionSubQuestion.conditionSubVision = false;
                this._conditionSubQuestion.conditionSubHearing = false;
                this._conditionSubQuestion.conditionSubMobility = false;
                this._conditionSubQuestion.conditionSubDexterity = false;
                this._conditionSubQuestion.conditionSubLearning = false;
                this._conditionSubQuestion.conditionSubMemory = false;
                this._conditionSubQuestion.conditionSubStamina = false;
                this._conditionSubQuestion.conditionSubSocially = false;
                this._conditionSubQuestion.conditionSubNone = false;
                this._conditionSubQuestion.conditionSubOther = false;
            } else {
                this._conditionSubQuestion.conditionSubPreferNotToSay = false;
            }
        }
        if (this._conditionSubQuestion.conditionSubOther) {
            this._conditionSubQuestion.conditionSubOtherText =
                formObj.conditionSubOtherText;
        } else {
            this._conditionSubQuestion.conditionSubOtherText = "";
        }
    }

    initializeConditionSubQuestion(): void {
        this._conditionSubQuestion = {
            conditionSubVision: false,
            conditionSubHearing: false,
            conditionSubMobility: false,

            conditionSubDexterity: false,
            conditionSubLearning: false,
            conditionSubMemory: false,
            conditionSubStamina: false,
            conditionSubSocially: false,
            conditionSubNone: false,
            conditionSubOther: false,
            conditionSubPreferNotToSay: false,
            conditionSubOtherText: "",
        };
    }
}
