export const routesNames = {
    EVIDENCE_SUBMISSION: "equalities_form",
    REASON_FOR_SENDING: "reason_for_sending",
    APPLICATION_INFO: "application_info",
    AGE_QUESTION: "age_question",
    UPLOAD_DOCUMENTS: "submit_form",
    DOC_UPLOAD_CONFIRMATION: "confirmation",
    ETHNIC_QUESTION: "ethnic_question",
    ETHNIC_SUB_QUESTION: "ethnic_sub_question",
    ETHNIC_OTHER_QUESTION: "ethnic_other_question",
    GENDER_QUESTION: "gender_question",
    TRANS_QUESTION: "trans_question",
    CONDITION_QUESTION: "condition_question",
    CONDITION_SUB_QUESTION: "condition_sub_question",
    PREFERENCE_QUESTION: "preference_question",
    RELIGION_QUESTION: "religion_question",
    SERVICE_QUESTION: "service_question",
    PERSON_REF: "person_ref",
};
