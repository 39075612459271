export const documentType = {
    BSG: [
        {
            key: "Birth certificate",
            value: "Birth certificate"
        },
        {
            key: "Mat B1",
            value: "Mat B1"
        },
        {
            key: "Court order (kinship care or guardianship)",
            value: "Court order"
        },
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration or residence document",
            value: "Immigration or residence document"
        },
        {
            key: "Other",
            value: "Other"
        }
    ],
    FEA: [
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Funeral bill",
            value: "Funeral bill"
        },
        {
            key: "Travel bills/receipts",
            value: "Travel bills/receipts"
        },
        {
            key: "Death certificate",
            value: "Death certificate"
        },
        {
            key: "Still birth certificate",
            value: "Still birth certificate"
        },
        {
            key: "Insurance policy documents",
            value: "Insurance policy documents"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration or residence document",
            value: "Immigration or residence document"
        },
        {
            key: "Other",
            value: "Other"
        }
    ],
    JG: [
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Job offer",
            value: "Job offer"
        },
        {
            key: "Care leaver form",
            value: "Care leaver form"
        },
        {
            key: "Legal order document",
            value: "Legal order document"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration or residence document",
            value: "Immigration or residence document"
        },
        {
            key: "Other",
            value: "Other"
        }
    ],
    YCG: [
        {
            key: "Birth certificate",
            value: "Birth certificate"
        },
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration or residence document",
            value: "Immigration or residence document"
        },
        {
            key: "Current Educational Maintenance Allowance (EMA) award letter",
            value: "EMA award letter"
        },
        {
            key: "Current Student Awards Agency Scotland (SAAS) award letter",
            value: "Saas award letter"
        },
        {
            key: "Valid Young Scot Card with PASS logo",
            value: "Valid Young Scot Card with PASS logo"
        },
        {
            key: "Other",
            value: "Other"
        }
    ],
    SCP: [
        {
            key: "Birth certificate",
            value: "Birth certificate"
        },
        {
            key: "Mat B1",
            value: "Mat B1"
        },
        {
            key: "Court order (kinship care or guardianship)",
            value: "Court order"
        },
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration or residence document",
            value: "Immigration or residence document"
        },
        {
            key: "Other",
            value: "Other"
        }
    ],
    DEFAULT: [
        {
            key: "Driving license",
            value: "Driving license"
        },
        {
            key: "Passport",
            value: "Passport"
        },
        {
            key: "Bank or building society document",
            value: "Bank or building society document"
        },
        {
            key: "Tax letter from HMRC",
            value: "Tax letter from HMRC"
        },
        {
            key: "Official government letter",
            value: "Official government letter"
        },
        {
            key: "Identity document",
            value: "Identity document"
        },
        {
            key: "Tenancy agreement/mortgage statement",
            value: "Tenancy agreement/mortgage statement"
        },
        {
            key: "Utility bill",
            value: "Utility bill"
        },
        {
            key: "Legal or court document",
            value: "Legal or court document"
        },
        {
            key: "Immigration and residence document",
            value: "Immigration and residence document"
        },
        {
            key: "Benefit letter",
            value: "Benefit letter"
        },
        {
            key: "Birth certifcate ",
            value: "Birth certifcate "
        },
        {
            key: "Mat B1",
            value: "Mat B1"
        },
        {
            key: "Court order (kinship care or guardianship)",
            value: "Court order"
        },
        {
            key: "Funeral bill",
            value: "Funeral bill"
        },
        {
            key: "Travel bills/reciepts",
            value: "Travel bills/reciepts"
        },
        {
            key: "Death certificate",
            value: "Death certificate"
        },
        {
            key: "Still birth certificate",
            value: "Still birth certificate"
        },
        {
            key: "Insurance policy documents",
            value: "Insurance policy documents"
        },
        {
            key: "Bank Statements",
            value: "Bank Statements"
        },
        {
            key: "Current Educational Maintenance Allowance (EMA) award letter",
            value: "EMA award letter"
        },
        {
            key: "Current Student Awards Agency Scotland (SAAS) award letter",
            value: "Saas award letter"
        },
        {
            key: "Valid Young Scot Card with PASS logo",
            value: "Valid Young Scot Card with PASS logo"
        },
        {
            key: "Job offer",
            value: "Job offer"
        },
        {
            key: "Care leaver form",
            value: "Care leaver form"
        },
        {
            key: "Legal order document",
            value: "Legal order document"
        },
        {
            key: "Other",
            value: "Other"
        }
    ]
};
