import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ReasonForSendingService } from '../reason-for-sending/reason-for-sending.service';
import { routesNames } from "../routes.names";

@Injectable({
  providedIn: "root"
})
export class FormsGuard implements CanActivate {

  constructor(private reasonForSendingService: ReasonForSendingService,
              private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isFormActivity();
  }

  isFormActivity(): boolean {
    if (this.reasonForSendingService.reasonForSending) {
      return true;
    }
    this.router.navigate([routesNames.EVIDENCE_SUBMISSION]);
    return false;
  }

}
