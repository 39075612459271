import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Gender } from "./gender.enum";
import { GenderQuestionService } from "./gender-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";

import { AnalyticsService } from "../core/analytics.service";

@Component({
    selector: "app-gender-question",
    templateUrl: "./gender-question.component.html",
})
export class GenderQuestionComponent implements OnInit, AfterViewInit {
    public GenderEnum = Gender;
    public showOtherQuestion: Observable<boolean>;

    genderQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private genderQuestionService: GenderQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.genderQuestionForm = this.fb.group({
            gender: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            genderOther: [""],
        });
        this.genderQuestionForm.patchValue(
            this.genderQuestionService.genderQuestion
        );

        if (this.genderQuestionService.genderQuestion.gender == Gender.Other) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.genderQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    genderQuestionSubmit(): void {
        if (this.genderQuestionForm.valid) {
            this.saveToStore(this.genderQuestionForm.value);
            this.router
                .navigate([routesNames.TRANS_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                            {
                                event_category: routesNames.REASON_FOR_SENDING,
                                event_label: routesNames.APPLICATION_INFO,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.genderQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.genderQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.genderQuestionService.updateGenderQuestion(value);

        if (this.genderQuestionService.genderQuestion.gender == Gender.Other) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }

        if (this.genderQuestionService.genderQuestion.gender == Gender.Other) {
            this.genderQuestionForm.controls["genderOther"].setValidators([
                Validators.required,
            ]);
        } else {
            this.genderQuestionForm.controls["genderOther"].setValidators(null);
            this.genderQuestionForm.controls["genderOther"].setErrors(null);
        }
    }

    goBack(): void {
        if (
            this.genderQuestionService.genderQuestion.previous_page ==
            "ETHNIC_QUESTION"
        ) {
            this.router.navigate([routesNames.ETHNIC_QUESTION]);
        } else if (
            this.genderQuestionService.genderQuestion.previous_page ==
            "ETHNIC_SUB_QUESTION"
        ) {
            this.router.navigate([routesNames.ETHNIC_SUB_QUESTION]);
        } else {
            this.router.navigate([routesNames.ETHNIC_OTHER_QUESTION]);
        }
    }
}
