import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Ethnicsub } from "./ethnic-sub.enum";
import { EthnicSubQuestionService } from "./ethnic-sub-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Ethnic } from "../ethnic-question/Ethnic.enum";

import { AnalyticsService } from "../core/analytics.service";
import { EthnicQuestionService } from "../ethnic-question/ethnic-question.service";
import { EthnicOtherQuestionService } from "../ethnic-other-question/ethnic-other-question.service";
import { GenderQuestionService } from "../gender-question/gender-question.service";

@Component({
    selector: "app-ethnic-sub-question",
    templateUrl: "./ethnic-sub-question.component.html",
})
export class EthnicSubQuestionComponent implements OnInit, AfterViewInit {
    public EthnicsubEnum = Ethnicsub;
    public White: boolean;
    public Mixed: boolean;
    public Asian: boolean;
    public African: boolean;
    public Caribbean: boolean;
    public Other: boolean;
    ethnicSubQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private ethnicSubQuestionService: EthnicSubQuestionService,
        private analytics: AnalyticsService,
        private etnicQuestionService: EthnicQuestionService,
        private ethnicOtherQuestionService: EthnicOtherQuestionService,
        private genderQuestionService: GenderQuestionService
    ) {}

    ngOnInit() {
        this.ethnicSubQuestionForm = this.fb.group({
            ethnicsub: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
        });
        this.ethnicSubQuestionForm.patchValue(
            this.ethnicSubQuestionService.ethnicSubQuestion
        );
        if (this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.White) {
            this.White = true;
        } else {
            this.White = false;
        }
        if (this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.Mixed) {
            this.Mixed = true;
            this.genderQuestionService.genderQuestion.previous_page =
                "ETHNIC_OTHER_QUESTION";
            this.router.navigate([routesNames.ETHNIC_OTHER_QUESTION]);
        } else {
            this.Mixed = false;
        }
        if (this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.Asian) {
            this.Asian = true;
        } else {
            this.Asian = false;
        }
        if (this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.African) {
            this.African = true;
        } else {
            this.African = false;
        }
        if (
            this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.Caribbean
        ) {
            this.Caribbean = true;
        } else {
            this.Caribbean = false;
        }
        if (this.etnicQuestionService.ethnicQuestion.ethnic == Ethnic.Other) {
            this.Other = true;
        } else {
            this.Other = false;
        }
        if (
            this.etnicQuestionService.ethnicQuestion.ethnic ==
            Ethnic.PreferNotToSay
        ) {
            this.genderQuestionService.genderQuestion.previous_page =
                "ETHNIC_QUESTION";
            this.router.navigate([routesNames.GENDER_QUESTION]);
        }
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.ethnicSubQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStoreChange(value)
        );
    }

    ethnicSubQuestionSubmit(): void {
        if (this.ethnicSubQuestionForm.valid) {
            this.saveToStore(this.ethnicSubQuestionForm.value);
            if (
                this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                    Ethnicsub.AfricanOther ||
                this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                    Ethnicsub.AsianOther ||
                this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                    Ethnicsub.WhiteOther ||
                this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                    Ethnicsub.CaribbeanOther ||
                this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                    Ethnicsub.OtherOther ||
                this.etnicQuestionService.ethnicQuestion.ethnicOtherRequired
            ) {
                this.ethnicOtherQuestionService.ethnicOtherQuestion.previous_page =
                    "ETHNIC_OTHER_QUESTION";

                this.router
                    .navigate([routesNames.ETHNIC_OTHER_QUESTION])
                    .then((res: boolean) => {
                        if (res) {
                            this.analytics.event(
                                `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                                {
                                    event_category:
                                        routesNames.REASON_FOR_SENDING,
                                    event_label: routesNames.APPLICATION_INFO,
                                }
                            );
                        }
                    });
            } else {
                this.genderQuestionService.genderQuestion.previous_page =
                    "ETHNIC_SUB_QUESTION";
                this.router
                    .navigate([routesNames.GENDER_QUESTION])
                    .then((res: boolean) => {
                        if (res) {
                            this.analytics.event(
                                `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                                {
                                    event_category:
                                        routesNames.REASON_FOR_SENDING,
                                    event_label: routesNames.APPLICATION_INFO,
                                }
                            );
                        }
                    });
            }
        } else {
            this.isSubmitted = true;
            this.ethnicSubQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.ethnicSubQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.ethnicSubQuestionService.updateEthnicSubQuestion(value);
    }

    saveToStoreChange(value: any): void {
        this.ethnicSubQuestionService.updateEthnicSubQuestion(value);
        this.ethnicOtherQuestionService.updateEthnicOtherQuestion(value);
        if (
            this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                Ethnicsub.AfricanOther ||
            this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                Ethnicsub.AsianOther ||
            this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                Ethnicsub.WhiteOther ||
            this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                Ethnicsub.CaribbeanOther ||
            this.ethnicSubQuestionService.ethnicSubQuestion.ethnicsub ==
                Ethnicsub.OtherOther ||
            this.etnicQuestionService.ethnicQuestion.ethnicOtherRequired
        ) {
            this.genderQuestionService.genderQuestion.previous_page =
                "ETHNIC_OTHER_QUESTION";
        } else {
            this.genderQuestionService.genderQuestion.previous_page =
                "ETHNIC_SUB_QUESTION";
        }
    }

    goBack(): void {
        this.router.navigate([routesNames.ETHNIC_QUESTION]);
    }
}
