export enum Religion {
    None = "None",
    RomanCatholic = "Roman Catholic",
    ChurchOfScotland = "Church Of Scotland",
    OtherChristian = "Other Christian",
    Muslim = "Muslim",
    Buddhist = "Buddhist",
    Jewish = "Jewish",
    Pagan = "Pagan",
    Sikh = "Sikh",
    Hindu = "ChangeIHindunCircumstances",
    Other = "Other",
    PreferNotToSay = "Prefer not to say",
}
