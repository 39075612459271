import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Preference } from "./preference.enum";
import { PreferenceQuestionService } from "./preference-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { AnalyticsService } from "../core/analytics.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-preference-question",
    templateUrl: "./preference-question.component.html",
})
export class PreferenceQuestionComponent implements OnInit, AfterViewInit {
    public PreferenceEnum = Preference;
    public showOtherQuestion: Observable<boolean>;
    preferenceQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private preferenceQuestionService: PreferenceQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.preferenceQuestionForm = this.fb.group({
            preference: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            preferenceOther: [""],
        });
        this.preferenceQuestionForm.patchValue(
            this.preferenceQuestionService.preferenceQuestion
        );

        if (
            this.preferenceQuestionService.preferenceQuestion.preference ==
            Preference.Other
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.preferenceQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    preferenceQuestionSubmit(): void {
        if (this.preferenceQuestionForm.valid) {
            this.saveToStore(this.preferenceQuestionForm.value);
            this.router
                .navigate([routesNames.RELIGION_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                            {
                                event_category: routesNames.REASON_FOR_SENDING,
                                event_label: routesNames.APPLICATION_INFO,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.preferenceQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.preferenceQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.preferenceQuestionService.updatePreferenceQuestion(value);

        if (
            this.preferenceQuestionService.preferenceQuestion.preference ==
            Preference.Other
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }

        if (
            this.preferenceQuestionService.preferenceQuestion.preference ==
            Preference.Other
        ) {
            this.preferenceQuestionForm.controls[
                "preferenceOther"
            ].setValidators([Validators.required]);
        } else {
            this.preferenceQuestionForm.controls[
                "preferenceOther"
            ].setValidators(null);
            this.preferenceQuestionForm.controls["preferenceOther"].setErrors(
                null
            );
        }
    }

    goBack(): void {
        if (
            this.preferenceQuestionService.preferenceQuestion.previous_page ==
            "CONDITION_SUB_QUESTION"
        ) {
            this.router
                .navigate([routesNames.CONDITION_SUB_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:back-click`,
                            {
                                event_category: routesNames.APPLICATION_INFO,
                                event_label: routesNames.EVIDENCE_SUBMISSION,
                            }
                        );
                    }
                });
        } else {
            this.router.navigate([routesNames.CONDITION_QUESTION]);
        }
    }
}
