import { AgeQuestionService } from "../age-question/age-question.service";
import { ConditionQuestionService } from "../condition-question/condition-question.service";
import { ConditionSubQuestionService } from "../condition-sub-question/condition-sub-question.service";
import { EthnicOtherQuestionService } from "../ethnic-other-question/ethnic-other-question.service";
import { EthnicQuestionService } from "../ethnic-question/ethnic-question.service";
import { EthnicSubQuestionService } from "../ethnic-sub-question/ethnic-sub-question.service";
import { GenderQuestionService } from "../gender-question/gender-question.service";
import { PersonRefService } from "../person-ref/person-ref.service";
import { PreferenceQuestionService } from "../preference-question/preference-question.service";
import { ReligionQuestionService } from "../religion-question/religion-question.service";
import { ServiceQuestionService } from "../service-question/service-question.service";
import { TransQuestionService } from "../trans-question/trans-question.service";

export class EvidenceRequest {
    personRef: string;
    age: string;
    ethnic: string;
    ethnicSub: string;
    ethnicOther: string;
    gender: string;
    genderOther: string;
    trans: string;
    condition: string;
    conditionSubVision: boolean;
    conditionSubHearing: boolean;
    conditionSubMobility: boolean;
    conditionSubDexterity: boolean;
    conditionSubLearning: boolean;
    conditionSubMemory: boolean;
    conditionSubStamina: boolean;
    conditionSubSocially: boolean;
    conditionSubNone: boolean;
    conditionSubOther: boolean;
    conditionSubPreferNotToSay: boolean;
    conditionSubOtherText: string;
    googleRecaptcha: string;
    preference: string;
    preferenceOther: string;
    religion: string;
    religionOther: string;
    service: string;
    serviceComments: string;

    constructor(
        personRefService: PersonRefService,
        ageService: AgeQuestionService,
        ethnicService: EthnicQuestionService,
        ethnicSubService: EthnicSubQuestionService,
        ethnicOtherService: EthnicOtherQuestionService,
        genderService: GenderQuestionService,
        transService: TransQuestionService,
        conditionService: ConditionQuestionService,
        conditionSubService: ConditionSubQuestionService,
        preferenceService: PreferenceQuestionService,
        religionService: ReligionQuestionService,
        serviceService: ServiceQuestionService,

        uploadForm: any
    ) {
        // applicant details
        this.personRef = personRefService.personRef.person;
        this.age = ageService.ageQuestion.age;
        this.ethnic = ethnicService.ethnicQuestion.ethnic;
        this.ethnicSub = ethnicSubService.ethnicSubQuestion.ethnicsub;
        this.ethnicOther = ethnicOtherService.ethnicOtherQuestion.ethnicOther;
        this.gender = genderService.genderQuestion.gender;
        this.genderOther = genderService.genderQuestion.genderOther;
        this.trans = transService.transQuestion.trans;
        this.condition = conditionService.conditionQuestion.condition;
        this.conditionSubVision =
            conditionSubService.conditionSubQuestion.conditionSubVision;
        this.conditionSubHearing =
            conditionSubService.conditionSubQuestion.conditionSubHearing;
        this.conditionSubMobility =
            conditionSubService.conditionSubQuestion.conditionSubMobility;
        this.conditionSubDexterity =
            conditionSubService.conditionSubQuestion.conditionSubDexterity;
        this.conditionSubLearning =
            conditionSubService.conditionSubQuestion.conditionSubLearning;
        this.conditionSubMemory =
            conditionSubService.conditionSubQuestion.conditionSubMemory;
        this.conditionSubStamina =
            conditionSubService.conditionSubQuestion.conditionSubStamina;
        this.conditionSubSocially =
            conditionSubService.conditionSubQuestion.conditionSubSocially;
        this.conditionSubNone =
            conditionSubService.conditionSubQuestion.conditionSubNone;
        this.conditionSubOther =
            conditionSubService.conditionSubQuestion.conditionSubOther;
        this.conditionSubPreferNotToSay =
            conditionSubService.conditionSubQuestion.conditionSubPreferNotToSay;
        this.conditionSubOtherText =
            conditionSubService.conditionSubQuestion.conditionSubOtherText;
        this.preference = preferenceService.preferenceQuestion.preference;
        this.preferenceOther =
            preferenceService.preferenceQuestion.preferenceOther;
        this.religion = religionService.religionQuestion.religion;
        this.religionOther = religionService.religionQuestion.religionOther;
        this.service = serviceService.serviceQuestion.service;
        this.serviceComments = serviceService.serviceQuestion.serviceComments;

        this.googleRecaptcha = uploadForm.googleRecaptcha;
    }

    private removeSpaces(nino: string): string {
        nino = nino.replace(/ /g, "");
        return nino.toUpperCase();
    }
}
