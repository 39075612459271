import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, throwError, forkJoin } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";

import { SettingsProvider } from "../core/settings.provider";

import { ApplicationInfoService } from "../application-info/application-info.service";
import { EvidenceRequest } from "./evidence-request";
import { EvidenceResponse } from "./evidence-response";
import { PersonRefService } from "../person-ref/person-ref.service";
import { AgeQuestionService } from "../age-question/age-question.service";
import { EthnicQuestionService } from "../ethnic-question/ethnic-question.service";
import { EthnicSubQuestionService } from "../ethnic-sub-question/ethnic-sub-question.service";
import { EthnicOtherQuestionService } from "../ethnic-other-question/ethnic-other-question.service";
import { GenderQuestionService } from "../gender-question/gender-question.service";
import { TransQuestionService } from "../trans-question/trans-question.service";
import { ConditionQuestionService } from "../condition-question/condition-question.service";
import { ConditionSubQuestionService } from "../condition-sub-question/condition-sub-question.service";
import { PreferenceQuestionService } from "../preference-question/preference-question.service";
import { ReligionQuestionService } from "../religion-question/religion-question.service";
import { ServiceQuestionService } from "../service-question/service-question.service";

@Injectable({
    providedIn: "root",
})
export class UploadDocumentsService {
    constructor(
        private http: HttpClient,
        private settingsProvider: SettingsProvider,

        private applicationInfoService: ApplicationInfoService,
        private personRefService: PersonRefService,
        private ageQuestionService: AgeQuestionService,

        private ethnicService: EthnicQuestionService,
        private ethnicSubService: EthnicSubQuestionService,
        private ethnicOtherService: EthnicOtherQuestionService,
        private genderService: GenderQuestionService,
        private transService: TransQuestionService,
        private conditionService: ConditionQuestionService,
        private conditionSubService: ConditionSubQuestionService,
        private preferenceService: PreferenceQuestionService,
        private religionService: ReligionQuestionService,
        private serviceService: ServiceQuestionService
    ) {}

    public postDocuments(formValue: object): Observable<any> {
        const uploadUrl = `${this.settingsProvider.configuration.apiBaseUrl}`;

        const headers = new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
        });

        const evidenceRequest = this.mapToEvidenceRequest(formValue);
        return this.http
            .post<EvidenceResponse>(uploadUrl, evidenceRequest, {
                headers,
            })
            .pipe(
                concatMap((response) => {
                    evidenceRequest["envelopeID"] = response.envelopeID;
                    evidenceRequest.googleRecaptcha = undefined;

                    console.log(evidenceRequest);
                    return this.forkJoinS3Requests(response, formValue);
                }),
                concatMap((res) => {
                    return this.http.post(uploadUrl, evidenceRequest, {
                        headers,
                    });
                }),
                catchError(this.handleError)
            );
    }

    mapToEvidenceRequest(formValue: object): EvidenceRequest {
        const evidenceRequest = new EvidenceRequest(
            this.personRefService,
            this.ageQuestionService,
            this.ethnicService,
            this.ethnicSubService,
            this.ethnicOtherService,
            this.genderService,
            this.transService,
            this.conditionService,
            this.conditionSubService,
            this.preferenceService,
            this.religionService,
            this.serviceService,
            formValue
        );

        return evidenceRequest;
    }

    private forkJoinS3Requests(
        response: EvidenceResponse,
        formValue: any
    ): Observable<any[]> {
        const urls = [];
        const formBodies = [];
        for (let i = 0; i < response.uploadFiles.length; i++) {
            urls.push(response.uploadFiles[i].url);
            const formData = new FormData();
            for (const key of Object.keys(response.uploadFiles[i].fields)) {
                formData.append(key, response.uploadFiles[i].fields[key]);
            }
            formData.append("file", formValue.documents[i].file);
            formBodies.push(formData);
        }
        return forkJoin(
            urls.map((url, i) => this.http.post(url, formBodies[i]))
        );
    }

    private handleError(err) {
        let errorResponse: object;
        if (err.error instanceof ErrorEvent) {
            errorResponse = {
                statusCode: undefined,
                errorMessage: `An error occurred: ${err.error.message}`,
            };
        } else {
            errorResponse = {
                statusCode: err.status,
                errorMessage: `Backend_returned_code_${err.status}: ${err.error.errorMsg}`,
            };
            console.log(err);
        }
        return throwError(errorResponse);
    }
}
