import { Component, HostListener, OnInit } from "@angular/core";
import { routesNames } from "./routes.names";
import { AnalyticsService } from "./core/analytics.service";
import { ActivatedRoute, Params } from "@angular/router";
import { ContextService } from "./core/context.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {

  @HostListener("window:beforeunload", ["$event"]) alertUnload($event) {
    if (window.location.pathname !== "/" + routesNames.EVIDENCE_SUBMISSION) {
      return false;
    }
  }

  constructor(
    private analytics: AnalyticsService,
    private route: ActivatedRoute,
    private contextService: ContextService) {
    this.analytics.init();
  }

  ngOnInit(): void {
    this.initialiseContext();
  }

  initialiseContext(): void {
    const context = window.location.search.length === 0
      ? {}
      : window.location.search
        .substr(1)
        .split("&")
        .map(pairString => pairString.split("="))
        .reduce((out, pair) => {
          out[pair[0]] = pair[1];
          return out;
        }, {} as Params);
    if (Object.keys(context).length > 0) {
      this.contextService.context = context;
    }
  }
}
