export enum Ethnicsub {
    WhiteScottish = "Scottish",
    WhiteIrish = "Irish",
    WhitePolish = "Polish",
    WhiteOtherBritish = "Other British",
    WhiteOther = "White Other",
    WhiteGypsyTraveller = "Gypsy/Traveller",

    AsianPakistani = "Pakistani, Pakistani Scottish, or Pakistani British",
    AsianIndian = "Indian, Indian Scottish, or Indian British",
    AsianBangladeshi = "Bangladeshi, Bangladeshi Scottish, or Bangladeshi British",
    AsianChinese = "Chinese, Chinese Scottish, or Chinese British",
    AsianOther = "Asian Other",

    AfricanAfrican = "African, African Scottish or African British",
    AfricanOther = "African Other",

    CaribbeanCaribbean = "Caribbean, Caribbean Scottish, or Caribbean British",
    CaribbeanBlack = "Black, Black Scottish, or Black British",
    CaribbeanOther = "Caribbean Other",

    OtherArab = "Arab, Arab Scottish or Arab British",
    OtherOther = "Other Other",
}
