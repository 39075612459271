import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { AnalyticsService } from "../core/analytics.service";
import { ReasonForSendingService } from "../reason-for-sending/reason-for-sending.service";
import { routesNames } from "../routes.names";

@Component({
    selector: "app-doc-upload-confirmation",
    templateUrl: "./doc-upload-confirmation.component.html",
})
export class DocUploadConfirmationComponent implements OnInit, AfterViewInit {
    public success: boolean;
    public referenceNumber: string;
    public isCic = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private reasonForSendingService: ReasonForSendingService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.isCic =
            this.reasonForSendingService.reasonForSending.changeInCircumstances;
        this.activeRoute.queryParams.subscribe((queryParams) => {
            this.handleState(queryParams);
        });
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
    }

    newSubmission(): void {
        this.router
            .navigate([routesNames.EVIDENCE_SUBMISSION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.DOC_UPLOAD_CONFIRMATION}:new-submission-click`,
                        {
                            name: "New Submission",
                            value: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }

    private handleState(queryParams: Params): void {
        if (queryParams["success"] == "true") {
            this.success = true;
            this.referenceNumber = queryParams["referenceNumber"];
            this.analytics.event(
                `Evidence Submission Form | ${routesNames.DOC_UPLOAD_CONFIRMATION}:doc-submission-success`,
                {
                    name: "Document Upload",
                    value: "Submitted",
                }
            );
        } else {
            this.success = false;
            this.analytics.event(
                `Evidence Submission Form | ${routesNames.DOC_UPLOAD_CONFIRMATION}:doc-submission-error`,
                {
                    name: "Document Upload",
                    value: "Error",
                }
            );
        }
    }
}
