import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { ConditionSub } from "./condition-sub.enum";
import { ConditionSubQuestionService } from "./condition-sub-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";

import { AnalyticsService } from "../core/analytics.service";
import { Condition } from "../condition-question/condition.enum";

@Component({
    selector: "app-condition-sub-question",
    templateUrl: "./condition-sub-question.component.html",
})
export class ConditionSubQuestionComponent implements OnInit, AfterViewInit {
    public ConditionSubEnum = ConditionSub;
    private conditionSubVision = false;
    private conditionSubHearing = false;
    private conditionSubMobility = false;
    private conditionSubDexterity = false;
    private conditionSubLearning = false;
    private conditionSubMemory = false;
    private conditionSubStamina = false;
    private conditionSubSocially = false;
    private conditionSubNone = false;
    private conditionSubOther = false;
    private conditionSubPreferNotToSay = false;
    private form_value_none = false;
    public showOtherQuestion: Observable<boolean>;

    conditionSubQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private conditionSubQuestionService: ConditionSubQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.conditionSubQuestionForm = this.fb.group({
            conditionSubVision: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubHearing: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubMobility: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubDexterity: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubLearning: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubMemory: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubStamina: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubSocially: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubNone: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubOther: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubPreferNotToSay: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            conditionSubOtherText: [""],
        });
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );

        this.conditionSubQuestionForm
            .get("conditionSubVision")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubVision,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubHearing")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubHearing,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubMobility")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubMobility,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubDexterity")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubDexterity,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubLearning")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubLearning,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubMemory")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubMemory,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubStamina")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubStamina,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubSocially")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubSocially,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubNone")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubNone,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubOther")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubOther,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm
            .get("conditionSubPreferNotToSay")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubPreferNotToSay,
                { emitEvent: false }
            );
        this.conditionSubQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
        this.conditionSubQuestionForm
            .get("conditionSubOtherText")
            .setValue(
                this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubOtherText,
                { emitEvent: false }
            );
    }
    ngAfterContentInit(): void {
        if (
            this.conditionSubQuestionService.conditionSubQuestion
                .conditionSubOther
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }

        if (
            this.conditionSubQuestionService.conditionSubQuestion
                .conditionSubOther
        ) {
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setValidators([Validators.required]);
        } else {
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setValidators(null);
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setErrors(null);
        }
    }

    conditionSubQuestionSubmit(): void {
        if (this.conditionSubQuestionForm.valid) {
            if (
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubDexterity &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubHearing &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubLearning &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubMemory &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubMobility &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubNone &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubOther &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubPreferNotToSay &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubSocially &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubStamina &&
                !this.conditionSubQuestionService.conditionSubQuestion
                    .conditionSubVision
            ) {
                this.isSubmitted = true;
                this.displayMessage = {
                    conditionSubQuestionMustSelectOne:
                        "You must answer this question. You may select 'Prefer not to say'. ",
                };
                setTimeout(() => {
                    const errorPopUp = document.getElementById("error-summary");
                    errorPopUp.scrollIntoView();
                    errorPopUp.querySelector("button").focus();
                });
            } else {
                this.router.navigate([routesNames.PREFERENCE_QUESTION]);
            }
        } else {
            this.isSubmitted = true;
            this.conditionSubQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.conditionSubQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        if (value != this.conditionSubQuestionService.conditionSubQuestion) {
            this.conditionSubQuestionService.updateConditionSubQuestion(value);

            this.conditionSubQuestionForm
                .get("conditionSubVision")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubVision,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubHearing")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubHearing,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubMobility")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubMobility,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubDexterity")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubDexterity,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubLearning")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubLearning,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubMemory")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubMemory,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubStamina")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubStamina,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubSocially")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubSocially,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubNone")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubNone,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubOther")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubOther,
                    { emitEvent: false }
                );
            this.conditionSubQuestionForm
                .get("conditionSubPreferNotToSay")
                .setValue(
                    this.conditionSubQuestionService.conditionSubQuestion
                        .conditionSubPreferNotToSay,
                    { emitEvent: false }
                );
        } else {
        }

        if (
            this.conditionSubQuestionService.conditionSubQuestion
                .conditionSubOther
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }

        if (
            this.conditionSubQuestionService.conditionSubQuestion
                .conditionSubOther
        ) {
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setValidators([Validators.required]);
        } else {
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setValidators(null);
            this.conditionSubQuestionForm.controls[
                "conditionSubOtherText"
            ].setErrors(null);
        }
    }

    goBack(): void {
        this.router
            .navigate([routesNames.EVIDENCE_SUBMISSION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.CONDITION_QUESTION}:back-click`,
                        {
                            event_category: routesNames.APPLICATION_INFO,
                            event_label: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }
}
