import {
    AbstractControl,
    ValidatorFn,
    FormGroup,
    FormArray,
} from "@angular/forms";
import { regex } from "../regex.constants";
import { validationMessages } from "./validation-messages";

export class CustomValidator {
    static applicantName(controlName: string): ValidatorFn {
        return (
            name: AbstractControl
        ): { [key: string]: boolean | string } | null => {
            const illegalCharaterArray = [];

            if (name.pristine || !name.value || name.value.length < 2) {
                return null;
            }

            // check whether valid
            if (regex.NAME.test(name.value)) {
                return null;
            }

            // check name starts with spl character
            if (regex.DISALLOWED_BEGIN_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedbeginsequence"
                );
            }

            // check name ends with spl character
            if (regex.DISALLOWED_END_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedendsequence"
                );
            }

            // check for disallowed sequence
            if (regex.DISALLOWED_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedsequence"
                );
            }

            for (let i = 0; i < name.value.length; i++) {
                const character = name.value[i];
                if (!character.match(regex.ALLOWED_SPECIAL_CHARS)) {
                    if (illegalCharaterArray.indexOf(character) < 0) {
                        illegalCharaterArray.push(character);
                    }
                }
            }

            if (illegalCharaterArray.length > 0) {
                return {
                    ["invalid"]: true,
                };
            }

            return CustomValidator.mapValidationMessage("invalid");
        };
    }

    static code(controlName: string): ValidatorFn {
        return (
            name: AbstractControl
        ): { [key: string]: boolean | string } | null => {
            const illegalCharaterArray = [];

            if (name.pristine || !name.value || name.value.length < 2) {
                return null;
            }

            // check whether valid
            if (regex.NAME.test(name.value)) {
                return null;
            }

            // check name starts with spl character
            if (regex.DISALLOWED_BEGIN_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedbeginsequence"
                );
            }

            // check name ends with spl character
            if (regex.DISALLOWED_END_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedendsequence"
                );
            }

            // check for disallowed sequence
            if (regex.DISALLOWED_SEQUENCE.test(name.value)) {
                return CustomValidator.mapValidationMessage(
                    "disallowedsequence"
                );
            }

            for (let i = 0; i < name.value.length; i++) {
                const character = name.value[i];
                if (!character.match(regex.ALLOWED_SPECIAL_CHARS)) {
                    if (illegalCharaterArray.indexOf(character) < 0) {
                        illegalCharaterArray.push(character);
                    }
                }
            }

            if (illegalCharaterArray.length > 0) {
                return {
                    ["invalid"]: true,
                };
            }

            return CustomValidator.mapValidationMessage("invalid");
        };
    }

    static dateOfBirth(
        dob: AbstractControl
    ): { [key: string]: boolean } | null {
        const day: AbstractControl = dob.get("day");
        const month = dob.get("month");
        const year = dob.get("year");

        if (
            dob.pristine &&
            !(
                (day.touched || day.dirty) &&
                (month.touched || month.dirty) &&
                (year.touched || year.dirty)
            )
        ) {
            return null;
        }

        if (day.value && month.value && year.value) {
            const dd = parseInt(day.value);
            const mm = parseInt(month.value);
            const yyyy = parseInt(year.value);

            // Create list of days of a month [assume there is no leap year by default]
            const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            // check year is between 1900 - 2100
            if (
                !(dd > 0 && dd < 32) ||
                !(mm > 0 && mm < 13) ||
                !(yyyy >= 1900 && yyyy <= 2100)
            ) {
                return CustomValidator.mapValidationMessage("invaliddate");
            }
            // check number of days in a month 30 or 31 depending on month
            if (mm == 1 || mm > 2) {
                if (dd > ListofDays[mm - 1]) {
                    return CustomValidator.mapValidationMessage("invaliddate");
                }
            }
            // check for leap year
            if (mm == 2) {
                let lyear = false;
                if ((!(yyyy % 4) && yyyy % 100) || !(yyyy % 400)) {
                    lyear = true;
                }
                if (lyear == false && dd >= 29) {
                    return CustomValidator.mapValidationMessage("invaliddate");
                }
                if (lyear == true && dd > 29) {
                    return CustomValidator.mapValidationMessage("invaliddate");
                }
            }

            const enteredDate = new Date(
                `${yyyy}-${mm < 10 ? "0" + mm : mm}-${dd < 10 ? "0" + dd : dd}`
            );

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (enteredDate > today) {
                return CustomValidator.mapValidationMessage("futuredate");
            }
        }

        return null;
    }

    // Validates file extensions
    static fileValidator(control: AbstractControl) {
        const file: File = control.value;
        if (file) {
            if (!file.name.match(regex.ALLOWED_FILE_EXTENTIONS)) {
                return {
                    fileType: true,
                };
            }

            if (file.size > 314572800) {
                return {
                    fileSize: true,
                };
            }
            return null;
        }
        return null;
    }

    static totalFileSize(
        documents: FormArray
    ): { [key: string]: boolean } | null {
        if (documents.pristine) {
            return null;
        }

        const validList = [];
        documents.controls.forEach((control) => {
            validList.push(control.valid);
        });

        if (validList.length > 1 && validList.every((x) => x)) {
            const size = documents.value.reduce((sum, obj) => {
                return sum + obj.file.size;
            }, 0);
            if (size > 314572800) {
                return {
                    totalFileSize: true,
                };
            }
        }

        return null;
    }

    static processMessages(container: FormGroup): { [key: string]: string } {
        const messages = {};

        for (const controlKey in container.controls) {
            if (container.controls.hasOwnProperty(controlKey)) {
                const c = container.controls[controlKey];

                // If it is a FormGroup, process its child controls.
                if (c instanceof FormGroup) {
                    const childMessages = this.processMessages(c);
                    Object.assign(messages, childMessages);
                }

                // If it is a FormGroup, process its child controls.
                if (c instanceof FormArray) {
                    if (c.errors) {
                        Object.keys(c.errors).map((messageKey) => {
                            if (validationMessages[controlKey][messageKey]) {
                                messages[controlKey] +=
                                    validationMessages[controlKey][messageKey] +
                                    " ";
                            }
                        });
                    }

                    for (let i = 0; i < c.controls.length; i++) {
                        const childMessages = this.processMessages(
                            c.controls[i] as FormGroup
                        );
                        for (const mkey in childMessages) {
                            messages[mkey + (i + 1)] = childMessages[mkey];
                        }
                    }
                }
                // Only validate if there are validation messages for the control
                if (validationMessages[controlKey]) {
                    messages[controlKey] = "";

                    if ((c.dirty || c.touched) && c.errors) {
                        Object.keys(c.errors).map((messageKey) => {
                            if (validationMessages[controlKey][messageKey]) {
                                messages[controlKey] +=
                                    validationMessages[controlKey][messageKey] +
                                    " ";
                            }
                            if (messageKey === "message") {
                                messages[controlKey] += c.errors[messageKey];
                            }
                        });
                    }
                }
            }
        }
        return messages;
    }

    private static mapValidationMessage(key: string): {
        [key: string]: boolean;
    } {
        return {
            [key]: true,
        };
    }
}
