import { Injectable } from "@angular/core";
import { Gender } from "./gender.enum";

export interface GenderQuestion {
    gender: string;
    previous_page: string;
    genderOther: string;
}

@Injectable({
    providedIn: "root",
})
export class GenderQuestionService {
    private _genderQuestion: GenderQuestion;

    constructor() {}

    get genderQuestion() {
        return this._genderQuestion;
    }

    updateGenderQuestion(formObj: GenderQuestion): void {
        switch (formObj.gender) {
            case Gender.Man: {
                this._genderQuestion.gender = Gender.Man;
                this._genderQuestion.genderOther = "";
                break;
            }
            case Gender.Woman: {
                this._genderQuestion.gender = Gender.Woman;
                this._genderQuestion.genderOther = "";

                break;
            }
            case Gender.Other: {
                this._genderQuestion.gender = Gender.Other;
                this._genderQuestion.genderOther = formObj.genderOther;

                break;
            }
            case Gender.PreferNotToSay: {
                this._genderQuestion.gender = Gender.PreferNotToSay;
                this._genderQuestion.genderOther = "";

                break;
            }
        }
    }

    initializeGenderQuestion(): void {
        this._genderQuestion = {
            gender: "",
            genderOther: "",
            previous_page: "ETHNIC_SUB_QUESTION",
        };
    }
}
