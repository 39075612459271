export enum Age {
    Under18 = "Under 18",
    A18to29 = "18 to 29",
    A30to39 = "30 to 39",
    A40to49 = "40 to 49",
    A50to59 = "50 to 59",
    A60to69 = "60 to 69",
    A70to79 = "70 to 79",
    Over80 = "Over 80",
    PreferNotToSay = "Prefer not to say",
}
