import { Injectable } from "@angular/core";
import { EthnicOther } from "./ethnicOther.enum";

export interface EthnicOtherQuestion {
    ethnicOther: string;
    previous_page: string;
}

@Injectable({
    providedIn: "root",
})
export class EthnicOtherQuestionService {
    private _ethnicOtherQuestion: EthnicOtherQuestion;

    constructor() {}

    get ethnicOtherQuestion() {
        return this._ethnicOtherQuestion;
    }

    updateEthnicOtherQuestion(Any): void {
        if (Any == "Reset" || Any.ethnicOther === undefined) {
            this._ethnicOtherQuestion.ethnicOther = "";
        } else {
            this._ethnicOtherQuestion.ethnicOther = Any.ethnicOther;
        }
        console.log(this._ethnicOtherQuestion.ethnicOther);
    }

    initializeEthnicOtherQuestion(): void {
        this._ethnicOtherQuestion = {
            ethnicOther: " ",
            previous_page: "ETHNIC_QUESTION",
        };
    }
}
