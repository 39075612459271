// Defines all of the validation messages for the form.
// These could instead be retrieved from a file or database.
export const validationMessages: { [key: string]: { [key: string]: string } } =
    {
        reason: {
            required: "Select the reason you’re sending us documents",
        },
        age: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        ethnic: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        application: {
            required: "Select an application",
        },
        ethnicsub: {
            required:
                "You must answer this question. You may go back and select 'Prefer not to say' for the question 'What is your ethnic group?' if you don't want to give us this information.",
        },
        ethnicOther: {
            required:
                "You must answer this question. You may go back and select 'Prefer not to say' for the question 'What is your ethnic group?' if you don't want to give us this information.",
        },
        gender: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        genderOther: {
            required:
                "You must answer this question as you have selected 'In another way'. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        trans: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        condition: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        conditionSubOtherText: {
            required:
                "You must answer this question as you have selected 'Other'. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        preference: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },

        preferenceOther: {
            required:
                "You must answer this question as you have selected 'Other'. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        religion: {
            required:
                "You must answer this question. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        religionOther: {
            required:
                "You must answer this question as you have selected 'Other'. You may select 'Prefer not to say' if you don't want to give us this information.",
        },
        service: {
            required: "You must answer this question.",
        },
        serviceComments: {
            maxlength:
                "Your feedback must be less than 500 characters. If you wish to send more feedback, please get in touch with us via email at apply@redress-scheme.scot",
        },
        person: {
            required: "You must enter your code here.",
            pattern: "The code you have provided is not valid. ",
        },

        firstName: {
            required: "Enter a first name",
            minlength: "First name must be at least 2 characters",
            maxlength: "First names must be 70 characters or less",
            disallowedbeginsequence: "First names must start with a letter",
            disallowedendsequence: "First names must end with a letter",
            disallowedsequence:
                "First names can't include more than one space, . ' or - characters in a row",
            invalid:
                "First names can only include letters, space, . ' or - characters",
        },
        lastName: {
            required: "Enter a last name",
            minlength: "Last name must be at least 2 characters",
            maxlength: "Last name must be 35 characters or less",
            disallowedbeginsequence: "Last names must start with a letter",
            disallowedendsequence: "Last name must end with a letter",
            disallowedsequence:
                "Last name can't include more than one space, . ' or - characters in a row",
            invalid:
                "Last name can only include letters, space, . ' or - characters",
        },
        day: {
            required: "Enter a date of birth",
        },
        month: {
            required: "Enter a date of birth",
        },
        year: {
            required: "Enter a date of birth",
        },
        dob: {
            futuredate: "Date of birth must be in the past",
            invaliddate: "Enter a valid date of birth",
        },
        nino: {
            pattern: "Enter a valid National Insurance number",
        },
        phoneNumber: {
            required: "Enter a phone number",
            minlength: "Phone number must be at least 11 digits",
            maxlength: "Phone number must be 20 digits or less",
            pattern: "Phone number can only include numbers",
        },

        city: {
            required: "Enter a town or city",
            disallowedbeginsequence:
                "Town or city must start with a letter or number",
            disallowedendsequence:
                "Town or city must end with a letter or number",
            disallowedsequence:
                "Town or city can't include two or more symbols in a row",
            invalid:
                "Town or city can only include letters, space, . ' or - characters",
        },
        postcode: {
            required: "Enter a UK postcode",
            pattern: "Enter a valid UK postcode",
        },
        file: {
            required: "You must choose a file",
            fileSize: "File size must not exceed 10MB",
            fileType:
                "Un-supported file type. Supported files include: DOCX, DOC, PDF, JPG, PNG, GIF, BMP, TIFF, PPTX, PPT, XLSX, XLS, JPEG, MP4, MOV, AVI, MKV, WMV.",
        },
        documentType: {
            required: "Select a document type",
        },
        googleRecaptcha: {
            required:
                "You must agree with this statement to continue with your submission",
        },
        online: {
            required:
                "Your internet connection may not be strong enough or may have disconnected. Before pressing submit, check your connection.",
        },
        documents: {
            totalFileSize: "Total file size must not exceed 10MB",
        },
    };
