import { Injectable } from "@angular/core";
import { Trans } from "./trans.enum";

export interface TransQuestion {
    trans: string;
}

@Injectable({
    providedIn: "root",
})
export class TransQuestionService {
    private _transQuestion: TransQuestion;

    constructor() {}

    get transQuestion() {
        return this._transQuestion;
    }

    updateTransQuestion(formObj: TransQuestion): void {
        switch (formObj.trans) {
            case Trans.Yes: {
                this._transQuestion.trans = Trans.Yes;

                break;
            }
            case Trans.PreferNotToSay: {
                this._transQuestion.trans = Trans.PreferNotToSay;

                break;
            }
            case Trans.No: {
                this._transQuestion.trans = Trans.No;

                break;
            }
        }
    }

    initializeTransQuestion(): void {
        this._transQuestion = {
            trans: "",
        };
    }
}
