
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { documentType } from "../upload-documents/documentType.constants";

@Injectable()
export class SettingsProvider {

    private config: any;

    constructor(private readonly http: HttpClient) {
    }

    public loadConfig(pathToConfig: string): Promise<any> {
        return this.http.get(pathToConfig)
            .toPromise()
            .then(settings => this.config = settings);
    }

    public get configuration(): any {
        return this.config;
    }

    public get apiBaseUrl(): string {
        return this.config ? this.config.apiBaseUrl : "";
    }

    public get environment(): string {
        return this.config ? this.config.env : "";
    }

    public get reCaptchaSiteKey(): string {
        return this.config ? this.config.reCaptchaSiteKey : "";
    }

    public get googleAnalyticsKey(): string {
        return this.config ? this.config.googleAnalyticsKey : "";
    }

    public get applicationCaseTransferOptions(): Array<any> {
        return this.config ? this.config.applicationCaseTransferOptions : [
            { name: "Child Disability Payment", value: "CDP" }
        ];
    }
    public get applicationDropDownOptions(): Array<any> {
        return this.config ? this.config.applicationDropDownOptions : [
            { name: "Best Start Grant & Best Start Foods", value: "BSG"},
            { name: "Funeral Support Payment", value: "FEA"},
            { name: "Job Start Payment", value: "JG"},
            { name: "Scottish Child Payment", value: "SCP"},
            { name: "Young Carer Grant", value: "YCG"},
            { name: "Child Disability Payment", value: "CDP" }
        ];
    }

    public get changeInCircumstancesOptions(): Array<any> {
        return this.config ? this.config.changeInCircumstancesOptions : [
            { name: 'Scottish Child Payment', value: 'SCP' },
            { name: 'Best Start Foods', value: 'BSG' },
            { name: "Child Disability Payment", value: "CDP" }
        ];
    }

    public get documentType(): any {
        return this.config ? this.config.documentType : documentType;
    }
}
