import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { SettingsProvider } from "./settings.provider";
import { AnalyticsService } from "./analytics.service";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
    providers: [{
            provide: APP_INITIALIZER,
            useFactory: init,
            deps: [SettingsProvider],
            multi: true
        },
        SettingsProvider,
        AnalyticsService
    ]
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, "CoreModule");
    }
}

export function init(settingsProvider: SettingsProvider): any {
    return () => settingsProvider.loadConfig("./client-config.json");
}
