import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { routesNames } from "../routes.names";
import { regex } from "../regex.constants";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ApplicationInfoService } from "./application-info.service";
import { AnalyticsService } from "../core/analytics.service";
import { ReasonForSendingService } from "../reason-for-sending/reason-for-sending.service";
import { SettingsProvider } from "../core/settings.provider";

@Component({
    selector: "app-application-info",
    templateUrl: "./application-info.component.html",
})
export class ApplicationInfoComponent implements OnInit, AfterViewInit {
    applicationInfoForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;
    isCic = false;
    isBenApp = false;
    isTransferDwp = false;
    isBenAppCdp = false;

    // drop down data
    applyApplicationOptions: Array<any>;
    applyCicOptions: Array<any>;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private applicationInfoService: ApplicationInfoService,
        private reasonForSendingService: ReasonForSendingService,
        private settingsProvider: SettingsProvider,
        private analytics: AnalyticsService
    ) {
        this.applyApplicationOptions =
            reasonForSendingService.reasonForSending.reason ===
            "TransferFromDwp"
                ? this.settingsProvider.applicationCaseTransferOptions
                : this.settingsProvider.applicationDropDownOptions;
        this.applyCicOptions =
            this.settingsProvider.changeInCircumstancesOptions;
    }

    ngOnInit(): void {
        this.isCic =
            this.reasonForSendingService.reasonForSending.changeInCircumstances;
        this.isBenApp =
            this.reasonForSendingService.reasonForSending.benefitApplication;
        this.isTransferDwp =
            this.reasonForSendingService.reasonForSending.transferFromDwp;

        let model = {
            firstName: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(70),
                        CustomValidator.applicantName("firstName"),
                    ],
                    updateOn: "blur",
                },
            ],
            lastName: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(35),
                        CustomValidator.applicantName("lastName"),
                    ],
                    updateOn: "blur",
                },
            ],
            dob: this.fb.group(
                {
                    day: [
                        "",
                        {
                            validators: [
                                Validators.required,
                                Validators.maxLength(2),
                            ],
                            updateOn: "blur",
                        },
                    ],
                    month: [
                        "",
                        {
                            validators: [
                                Validators.required,
                                Validators.maxLength(2),
                            ],
                            updateOn: "blur",
                        },
                    ],
                    year: [
                        "",
                        {
                            validators: [
                                Validators.required,
                                Validators.maxLength(4),
                            ],
                            updateOn: "blur",
                        },
                    ],
                },
                {
                    validators: [CustomValidator.dateOfBirth],
                    updateOn: "blur",
                }
            ),
            nino: [
                "",
                {
                    validators: [
                        Validators.maxLength(13),
                        Validators.pattern(regex.NI_NUMBER),
                    ],
                    updateOn: "blur",
                },
            ],
            phoneNumber: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(11),
                        Validators.maxLength(20),
                        Validators.pattern(regex.PHONE_NUMBER),
                    ],
                    updateOn: "blur",
                },
            ],
        };

        this.applicationInfoForm = this.fb.group(model);

        if (this.applicationInfoService.applicationInfo) {
            this.applicationInfoForm.patchValue(
                this.applicationInfoService.applicationInfo
            );
        }
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Evidence Submission Form - Scotland's Redress Scheme | Application Information"
        );
        this.applicationInfoForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    applicationInfoSubmit(): void {
        if (this.applicationInfoForm.valid) {
            this.saveToStore(this.applicationInfoForm.value);
            this.router
                .navigate([routesNames.UPLOAD_DOCUMENTS])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.APPLICATION_INFO}:next-click`,
                            {
                                event_category: routesNames.APPLICATION_INFO,
                                event_label: routesNames.UPLOAD_DOCUMENTS,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.applicationInfoForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.applicationInfoForm
            );

            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.applicationInfoService.updateApplicationInfo(value);
    }

    goBack(): void {
        this.router
            .navigate([routesNames.ETHNIC_QUESTION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.ETHNIC_QUESTION}:back-click`,
                        {
                            event_category: routesNames.APPLICATION_INFO,
                            event_label: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }
}
