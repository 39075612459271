import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Condition } from "./condition.enum";
import { ConditionQuestionService } from "./condition-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { AnalyticsService } from "../core/analytics.service";
import { PreferenceQuestionService } from "../preference-question/preference-question.service";

@Component({
    selector: "app-condition-question",
    templateUrl: "./condition-question.component.html",
})
export class ConditionQuestionComponent implements OnInit, AfterViewInit {
    public ConditionEnum = Condition;
    conditionQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private conditionQuestionService: ConditionQuestionService,
        private analytics: AnalyticsService,
        private preferenceQuestionService: PreferenceQuestionService
    ) {}

    ngOnInit() {
        this.conditionQuestionForm = this.fb.group({
            condition: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
        });
        this.conditionQuestionForm.patchValue(
            this.conditionQuestionService.conditionQuestion
        );
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.conditionQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    conditionQuestionSubmit(): void {
        if (this.conditionQuestionForm.valid) {
            if (
                this.conditionQuestionService.conditionQuestion.condition ==
                Condition.Yes
            ) {
                this.preferenceQuestionService.preferenceQuestion.previous_page =
                    "CONDITION_SUB_QUESTION";
                this.saveToStore(this.conditionQuestionForm.value);
                this.router.navigate([routesNames.CONDITION_SUB_QUESTION]);
            } else {
                this.preferenceQuestionService.preferenceQuestion.previous_page =
                    "CONDITION_QUESTION";
                this.saveToStore(this.conditionQuestionForm.value);
                this.router.navigate([routesNames.PREFERENCE_QUESTION]);
            }
        } else {
            this.isSubmitted = true;
            this.conditionQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.conditionQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.conditionQuestionService.updateConditionQuestion(value);
    }

    goBack(): void {
        this.router
            .navigate([routesNames.TRANS_QUESTION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:back-click`,
                        {
                            event_category: routesNames.APPLICATION_INFO,
                            event_label: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }
}
