import { Injectable } from "@angular/core";
import { Ethnic } from "./Ethnic.enum";

export interface EthnicQuestion {
    ethnic: string;
    ethnicOtherRequired: boolean;
}

@Injectable({
    providedIn: "root",
})
export class EthnicQuestionService {
    private _ethnicQuestion: EthnicQuestion;

    constructor() {}

    get ethnicQuestion() {
        return this._ethnicQuestion;
    }

    updateEthnicQuestion(formObj: EthnicQuestion): void {
        switch (formObj.ethnic) {
            case Ethnic.White: {
                this._ethnicQuestion.ethnic = Ethnic.White;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
            case Ethnic.Mixed: {
                this._ethnicQuestion.ethnic = Ethnic.Mixed;

                this._ethnicQuestion.ethnicOtherRequired = true;
                break;
            }
            case Ethnic.Asian: {
                this._ethnicQuestion.ethnic = Ethnic.Asian;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
            case Ethnic.African: {
                this._ethnicQuestion.ethnic = Ethnic.African;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
            case Ethnic.Caribbean: {
                this._ethnicQuestion.ethnic = Ethnic.Caribbean;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
            case Ethnic.Other: {
                this._ethnicQuestion.ethnic = Ethnic.Other;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
            case Ethnic.PreferNotToSay: {
                this._ethnicQuestion.ethnic = Ethnic.PreferNotToSay;

                this._ethnicQuestion.ethnicOtherRequired = false;
                break;
            }
        }
    }

    initializeEthnicQuestion(): void {
        this._ethnicQuestion = {
            ethnic: "",
            ethnicOtherRequired: false,
        };
    }
}
