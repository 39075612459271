import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EvidenceSubmissionComponent } from "./evidence-submission/evidence-submission.component";
import { ApplicationInfoComponent } from "./application-info/application-info.component";

import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { DocUploadConfirmationComponent } from "./doc-upload-confirmation/doc-upload-confirmation.component";
import { ReasonForSendingComponent } from "./reason-for-sending/reason-for-sending.component";
import { routesNames } from "./routes.names";
import { FormsGuard } from "./shared/forms.guard";
import { AgeQuestionComponent } from "./age-question/age-question.component";
import { EthnicQuestionComponent } from "./ethnic-question/ethnic-question.component";
import { EthnicSubQuestionComponent } from "./ethnic-sub-question/ethnic-sub-question.component";
import { EthnicOtherQuestionComponent } from "./ethnic-other-question/ethnic-other-question.component";
import { GenderQuestionComponent } from "./gender-question/gender-question.component";
import { TransQuestionComponent } from "./trans-question/trans-question.component";
import { ConditionQuestionComponent } from "./condition-question/condition-question.component";
import { ConditionSubQuestionComponent } from "./condition-sub-question/condition-sub-question.component";
import { PreferenceQuestionComponent } from "./preference-question/preference-question.component";
import { ReligionQuestionComponent } from "./religion-question/religion-question.component";
import { ServiceQuestionComponent } from "./service-question/service-question.component";
import { PersonRefComponent } from "./person-ref/person-ref.component";

const routes: Routes = [
    {
        path: routesNames.EVIDENCE_SUBMISSION,
        component: EvidenceSubmissionComponent,
    },
    {
        path: routesNames.REASON_FOR_SENDING,
        component: ReasonForSendingComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.TRANS_QUESTION,
        component: TransQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.SERVICE_QUESTION,
        component: ServiceQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.PERSON_REF,
        component: PersonRefComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.RELIGION_QUESTION,
        component: ReligionQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.PREFERENCE_QUESTION,
        component: PreferenceQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.CONDITION_SUB_QUESTION,
        component: ConditionSubQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.CONDITION_QUESTION,
        component: ConditionQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.GENDER_QUESTION,
        component: GenderQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.AGE_QUESTION,
        component: AgeQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.ETHNIC_QUESTION,
        component: EthnicQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.ETHNIC_SUB_QUESTION,
        component: EthnicSubQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.ETHNIC_OTHER_QUESTION,
        component: EthnicOtherQuestionComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.APPLICATION_INFO,
        component: ApplicationInfoComponent,
        canActivate: [FormsGuard],
    },

    {
        path: routesNames.UPLOAD_DOCUMENTS,
        component: UploadDocumentsComponent,
        canActivate: [FormsGuard],
    },
    {
        path: routesNames.DOC_UPLOAD_CONFIRMATION,
        component: DocUploadConfirmationComponent,
        canActivate: [FormsGuard],
    },
    {
        path: "",
        redirectTo: routesNames.EVIDENCE_SUBMISSION,
        pathMatch: "full",
    },

    {
        path: "**",
        redirectTo: routesNames.EVIDENCE_SUBMISSION,
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: "top",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
