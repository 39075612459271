import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ContextService } from "../context.service";

// Adds context headers to HttpClient requests.
@Injectable()
export class ContextInterceptor implements HttpInterceptor {

    constructor(private contextService: ContextService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(this.updateRequest(request));
    }

    private updateRequest(request: HttpRequest<any>): HttpRequest<any> {

        if (request.url.indexOf("/upload") > 0) {
            const context = this.contextService.context;
            Object.keys(context).forEach(key => {
                request = this.appendHeaderToRequest(request, "x-" + key, context[key]);
            });
        }
        return request;
    }

    private appendHeaderToRequest(request, key, value) {
        return request.clone({ headers: request.headers.set(key, value)});
    }
}
