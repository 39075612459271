import { Component, Input } from "@angular/core";
import { routesNames } from "../routes.names";

@Component({
    selector: "app-error-summary",
    templateUrl: "./error-summary.component.html",
})
export class ErrorSummaryComponent {
    routes = routesNames;
    @Input() displayMessage: { [key: string]: string };
    @Input() isSubmitted: boolean;
    @Input() isCicForm: boolean;

    constructor() {}

    scroll(id: string) {
        const el = document.getElementById(id);
        if (el.parentElement.previousElementSibling) {
            el.parentElement.previousElementSibling.scrollIntoView();
        } else {
            el.scrollIntoView();
            window.scrollBy(0, -40);
        }
        el.focus();
    }

    isFirstError(key: string): boolean {
        const errorLabel = [];
        for (const eKey of Object.keys(this.displayMessage)) {
            if (this.displayMessage[eKey]) {
                errorLabel.push(eKey);
            }
        }
        return errorLabel.indexOf(key) === 0 ? true : false;
    }
}
