import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { validationMessages } from "../shared/validation-messages";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-control-error",
  templateUrl: "./control-error.component.html"
})
export class ControlErrorComponent implements OnInit {

  errorMessages = validationMessages;
  errors = [];

  @Input() control: FormControl | FormGroup;
  @Input() name: string;

  constructor() { }

  ngOnInit() {
  }

}
