import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ContextService {

  private currentContext = {};

    get context(): any {
        return this.currentContext;
    }

    set context(value: any) {
        this.currentContext = value;
    }
}
