import { Injectable } from "@angular/core";
import { Age } from "./Age.enum";

export interface AgeQuestion {
    age: string;
}

@Injectable({
    providedIn: "root",
})
export class AgeQuestionService {
    private _ageQuestion: AgeQuestion;

    constructor() {}

    get ageQuestion() {
        return this._ageQuestion;
    }

    updateAgeQuestion(formObj: AgeQuestion): void {
        switch (formObj.age) {
            case Age.Under18: {
                this._ageQuestion.age = Age.Under18;
                break;
            }
            case Age.A18to29: {
                this._ageQuestion.age = Age.A18to29;
                break;
            }
            case Age.A30to39: {
                this._ageQuestion.age = Age.A30to39;
                break;
            }
            case Age.A40to49: {
                this._ageQuestion.age = Age.A40to49;
                break;
            }
            case Age.A50to59: {
                this._ageQuestion.age = Age.A50to59;
                break;
            }
            case Age.A60to69: {
                this._ageQuestion.age = Age.A60to69;
                break;
            }
            case Age.A70to79: {
                this._ageQuestion.age = Age.A70to79;
                break;
            }
            case Age.Over80: {
                this._ageQuestion.age = Age.Over80;
                break;
            }
            case Age.PreferNotToSay: {
                this._ageQuestion.age = Age.PreferNotToSay;
                break;
            }
        }
    }

    initializeAgeQuestion(): void {
        this._ageQuestion = {
            age: "",
        };
    }
}
