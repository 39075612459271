import { Injectable } from "@angular/core";
import { Person } from "./person.enum";

export interface PersonRef {
    person: string;
}

@Injectable({
    providedIn: "root",
})
export class PersonRefService {
    private _personRef: PersonRef;

    constructor() {}

    get personRef() {
        return this._personRef;
    }

    updatePersonRef(formObj: PersonRef): void {
        this._personRef.person = formObj.person;
    }

    initializePersonRef(): void {
        this._personRef = {
            person: "",
        };
    }
}
