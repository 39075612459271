import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { EthnicOther } from "./ethnicOther.enum";
import { EthnicOtherQuestionService } from "./ethnic-other-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { AnalyticsService } from "../core/analytics.service";

@Component({
    selector: "app-ethnic-other-question",
    templateUrl: "./ethnic-other-question.component.html",
})
export class EthnicOtherQuestionComponent implements OnInit, AfterViewInit {
    public EthnicOtherEnum = EthnicOther;
    ethnicOtherQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private ethnicOtherQuestionService: EthnicOtherQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.ethnicOtherQuestionForm = this.fb.group({
            ethnicOther: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
        });
        this.ethnicOtherQuestionForm.patchValue(
            this.ethnicOtherQuestionService.ethnicOtherQuestion
        );
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.ethnicOtherQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    ethnicOtherQuestionSubmit(): void {
        if (this.ethnicOtherQuestionForm.valid) {
            this.saveToStore(this.ethnicOtherQuestionForm.value);

            this.router
                .navigate([routesNames.GENDER_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                            {
                                event_category: routesNames.REASON_FOR_SENDING,
                                event_label: routesNames.APPLICATION_INFO,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.ethnicOtherQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.ethnicOtherQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.ethnicOtherQuestionService.updateEthnicOtherQuestion(value);
    }

    goBack(): void {
        if (
            this.ethnicOtherQuestionService.ethnicOtherQuestion.previous_page ==
            "ETHNIC_OTHER_QUESTION"
        ) {
            this.router
                .navigate([routesNames.ETHNIC_SUB_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:back-click`,
                            {
                                event_category: routesNames.APPLICATION_INFO,
                                event_label: routesNames.EVIDENCE_SUBMISSION,
                            }
                        );
                    }
                });
        } else {
            this.router.navigate([routesNames.ETHNIC_QUESTION]);
        }
    }
}
