import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Service } from "./service.enum";
import { ServiceQuestionService } from "./service-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { AnalyticsService } from "../core/analytics.service";

@Component({
    selector: "app-service-question",
    templateUrl: "./service-question.component.html",
})
export class ServiceQuestionComponent implements OnInit, AfterViewInit {
    public ServiceEnum = Service;
    serviceQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private serviceQuestionService: ServiceQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.serviceQuestionForm = this.fb.group({
            service: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            serviceComments: [""],
        });
        this.serviceQuestionForm.patchValue(
            this.serviceQuestionService.serviceQuestion
        );
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.serviceQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    serviceQuestionSubmit(): void {
        if (this.serviceQuestionForm.valid) {
            this.saveToStore(this.serviceQuestionForm.value);
            this.router
                .navigate([routesNames.UPLOAD_DOCUMENTS])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                            {
                                event_category: routesNames.REASON_FOR_SENDING,
                                event_label: routesNames.APPLICATION_INFO,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.serviceQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.serviceQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.serviceQuestionService.updateServiceQuestion(value);
    }

    goBack(): void {
        this.router
            .navigate([routesNames.RELIGION_QUESTION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:back-click`,
                        {
                            event_category: routesNames.RELIGION_QUESTION,
                            event_label: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }
}
