export const regex = {
    ALLOWED_FILE_EXTENTIONS:
        /^.*\.(DOCX|DOC|PDF|JPG|PNG|GIF|BMP|TIFF|PPTX|PPT|XLSX|XLS|JPEG|MP4|MOV|AVI|MKV|WMV)$/i,
    ALLOWED_SPECIAL_CHARS: /[a-zA-Z]|[.]|[\s]|[-]|[']/,
    DISALLOWED_SEQUENCE: /.*['\.\s\-]{2,}.*/,
    DISALLOWED_BEGIN_SEQUENCE: /^[!@#$%^&*(),.?":{}|<>]/,
    DISALLOWED_END_SEQUENCE: /[!@#$%^&*(),.?":{}|<>]$/,
    NAME: /^[a-zA-Z](?!.*--)(?!.*([-']){2})(?!.*([-.]){2})(?!.*([- ]){2})(?!.*'')(?!.*(['-]){2})(?!.*(['.]){2})(?!.*([' ]){2})(?!.*[..]{2})(?!.*([.-]){2})(?!.*([.']){2})(?!.*([. ]){2})(?!.*  )(?!.*([ -]){2})(?!.*([ ']){2})(?!.*([ .]){2})[a-zA-Z-'. ]*[a-zA-Z]$/,
    NI_NUMBER:
        /^( ?)(([Bb][ABCEHJ-NPR-TW-Zabcehj-npr-tw-z])|([Gg][ACEGHJ-NPR-TW-Zaceghj-npr-tw-z])|([Nn][ABCEGHJL-NPRSW-Zabceghjl-nprsw-z])|([Kk][ABCEGHJ-MPR-TW-Zabceghj-mpr-tw-z])|([Tt][ABCEGHJKL-MPRSTW-Zabceghjkl-mprstw-z])|([Zz][ABCEGHJ-NPR-TW-Yabceghj-npr-tw-y])|[ACEHJLMOPRSW-Yacehjlmoprsw-y][ABCEGHJ-NPRSTW-Zabceghj-nprstw-z]{1})( ?)[0-9]{2}( ?)[0-9]{2}( ?)[0-9]{2}( ?)[A-Da-d\s]{1}( ?)$/,
    PHONE_NUMBER: /^[0-9]*$/,
    POSTCODE:
        /^\b(GIRgir ?0AAaa|SANsan ?TAta1|(?:[A-PR-UWYZa-pr-uwyz](?:\d{0,2}|[A-HK-Ya-hk-y]\d|[A-HK-Ya-hk-y]\d\d|\d[A-HJKSTUWa-hjkstuw]|[A-HK-Ya-hk-y]\d[ABEHMNPRV-Yabehmnprv-y])) ?\d[ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2})\b$/,
    CODE: /^(APP|NOK)(\d){6}$/i,
};
