import { Injectable } from "@angular/core";
import { Preference } from "./preference.enum";

export interface PreferenceQuestion {
    preference: string;
    preferenceOther: string;
    previous_page: string;
}

@Injectable({
    providedIn: "root",
})
export class PreferenceQuestionService {
    private _preferenceQuestion: PreferenceQuestion;

    constructor() {}

    get preferenceQuestion() {
        return this._preferenceQuestion;
    }

    updatePreferenceQuestion(formObj: PreferenceQuestion): void {
        switch (formObj.preference) {
            case Preference.Hetero: {
                this._preferenceQuestion.preference = Preference.Hetero;
                this._preferenceQuestion.preferenceOther = "";
                break;
            }
            case Preference.Gay: {
                this._preferenceQuestion.preference = Preference.Gay;
                this._preferenceQuestion.preferenceOther = "";
                break;
            }
            case Preference.Bi: {
                this._preferenceQuestion.preference = Preference.Bi;
                this._preferenceQuestion.preferenceOther = "";
                break;
            }
            case Preference.Other: {
                this._preferenceQuestion.preference = Preference.Other;
                this._preferenceQuestion.preferenceOther =
                    formObj.preferenceOther;
                break;
            }
            case Preference.PreferNotToSay: {
                this._preferenceQuestion.preference = Preference.PreferNotToSay;
                this._preferenceQuestion.preferenceOther = "";
                break;
            }
        }
    }

    initializePreferenceQuestion(): void {
        this._preferenceQuestion = {
            preference: "",
            preferenceOther: "",
            previous_page: "CONIDTION_QUESTION",
        };
    }
}
