import { Injectable } from "@angular/core";
import { Service } from "./service.enum";

export interface ServiceQuestion {
    service: string;
    serviceComments: string;
}

@Injectable({
    providedIn: "root",
})
export class ServiceQuestionService {
    private _serviceQuestion: ServiceQuestion;

    constructor() {}

    get serviceQuestion() {
        return this._serviceQuestion;
    }

    updateServiceQuestion(formObj: ServiceQuestion): void {
        switch (formObj.service) {
            case Service.VeryGood: {
                this._serviceQuestion.service = Service.VeryGood;

                break;
            }
            case Service.Good: {
                this._serviceQuestion.service = Service.Good;

                break;
            }
            case Service.Neither: {
                this._serviceQuestion.service = Service.Neither;

                break;
            }
            case Service.Poor: {
                this._serviceQuestion.service = Service.Poor;

                break;
            }
            case Service.VeryPoor: {
                this._serviceQuestion.service = Service.VeryPoor;

                break;
            }
        }
        this._serviceQuestion.serviceComments = formObj.serviceComments;
    }

    initializeServiceQuestion(): void {
        this._serviceQuestion = {
            service: "",
            serviceComments: "",
        };
    }
}
