import { Component, OnInit } from "@angular/core";
import { routesNames } from "../routes.names";
import { Router } from "@angular/router";
import { AnalyticsService } from "../core/analytics.service";
import { Title } from "@angular/platform-browser";
import { ReasonForSendingService } from "../reason-for-sending/reason-for-sending.service";
import { AgeQuestionService } from "../age-question/age-question.service";
import { EthnicQuestionService } from "../ethnic-question/ethnic-question.service";
import { EthnicSubQuestionService } from "../ethnic-sub-question/ethnic-sub-question.service";
import { EthnicOtherQuestionService } from "../ethnic-other-question/ethnic-other-question.service";
import { GenderQuestionService } from "../gender-question/gender-question.service";
import { TransQuestionService } from "../trans-question/trans-question.service";
import { ConditionQuestionService } from "../condition-question/condition-question.service";
import { ConditionSubQuestionService } from "../condition-sub-question/condition-sub-question.service";
import { PreferenceQuestionService } from "../preference-question/preference-question.service";
import { ReligionQuestionService } from "../religion-question/religion-question.service";
import { ServiceQuestionService } from "../service-question/service-question.service";
import { PersonRefService } from "../person-ref/person-ref.service";

@Component({
    selector: "app-evidence-submission",
    templateUrl: "./evidence-submission.component.html",
})
export class EvidenceSubmissionComponent implements OnInit {
    constructor(
        private router: Router,
        private reasonForSendingService: ReasonForSendingService,
        private ageQuestionService: AgeQuestionService,
        private titleService: Title,
        private analytics: AnalyticsService,
        private ethnicQuestionService: EthnicQuestionService,
        private ethnicSubQuestionService: EthnicSubQuestionService,
        private ethnicOtherQuestionService: EthnicOtherQuestionService,
        private genderQuestionService: GenderQuestionService,
        private transQuestionService: TransQuestionService,
        private conditionQuestionService: ConditionQuestionService,
        private conditionSubQuestionService: ConditionSubQuestionService,
        private preferenceQuestionService: PreferenceQuestionService,
        private religionQuestionService: ReligionQuestionService,
        private serviceQuestionService: ServiceQuestionService,
        private personRefService: PersonRefService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
    }

    next(): void {
        if (!this.reasonForSendingService.reasonForSending) {
            this.reasonForSendingService.initializeReasonForSending();
        }
        if (!this.ageQuestionService.ageQuestion) {
            this.ageQuestionService.initializeAgeQuestion();
        }
        if (!this.ethnicQuestionService.ethnicQuestion) {
            this.ethnicQuestionService.initializeEthnicQuestion();
        }
        if (!this.ethnicSubQuestionService.ethnicSubQuestion) {
            this.ethnicSubQuestionService.initializeEthnicSubQuestion();
        }
        if (!this.ethnicOtherQuestionService.ethnicOtherQuestion) {
            this.ethnicOtherQuestionService.initializeEthnicOtherQuestion();
        }
        if (!this.genderQuestionService.genderQuestion) {
            this.genderQuestionService.initializeGenderQuestion();
        }
        if (!this.transQuestionService.transQuestion) {
            this.transQuestionService.initializeTransQuestion();
        }
        if (!this.conditionQuestionService.conditionQuestion) {
            this.conditionQuestionService.initializeConditionQuestion();
        }
        if (!this.conditionSubQuestionService.conditionSubQuestion) {
            this.conditionSubQuestionService.initializeConditionSubQuestion();
        }
        if (!this.preferenceQuestionService.preferenceQuestion) {
            this.preferenceQuestionService.initializePreferenceQuestion();
        }
        if (!this.religionQuestionService.religionQuestion) {
            this.religionQuestionService.initializeReligionQuestion();
        }
        if (!this.serviceQuestionService.serviceQuestion) {
            this.serviceQuestionService.initializeServiceQuestion();
        }
        if (!this.personRefService.personRef) {
            this.personRefService.initializePersonRef();
        }
        this.router.navigate([routesNames.PERSON_REF]).then((res: boolean) => {
            if (res) {
                this.analytics.event(
                    `Equalities Survey | ${routesNames.EVIDENCE_SUBMISSION}:next-click`,
                    {
                        event_category: routesNames.EVIDENCE_SUBMISSION,
                        event_label: routesNames.APPLICATION_INFO,
                    }
                );
            }
        });
    }
}
