import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { routesNames } from "../routes.names";
import { Religion } from "./religion.enum";
import { ReligionQuestionService } from "./religion-question.service";
import { CustomValidator } from "../shared/custom.validator";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { AnalyticsService } from "../core/analytics.service";

@Component({
    selector: "app-religion-question",
    templateUrl: "./religion-question.component.html",
})
export class ReligionQuestionComponent implements OnInit, AfterViewInit {
    public ReligionEnum = Religion;
    public showOtherQuestion: Observable<boolean>;
    religionQuestionForm: FormGroup;
    evidenceSubmissionLink = `/${routesNames.EVIDENCE_SUBMISSION}`;
    isSubmitted = false;

    // Use with the custom validation message class
    displayMessage: { [key: string]: string };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private titleService: Title,
        private religionQuestionService: ReligionQuestionService,
        private analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.religionQuestionForm = this.fb.group({
            religion: [
                "",
                {
                    validators: [Validators.required],
                },
            ],
            religionOther: [""],
        });
        this.religionQuestionForm.patchValue(
            this.religionQuestionService.religionQuestion
        );

        if (
            this.religionQuestionService.religionQuestion.religion ==
            Religion.Other
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }
    }

    ngAfterViewInit(): void {
        this.titleService.setTitle(
            "Equalities Survey - Scotland's Redress Scheme"
        );
        this.religionQuestionForm.valueChanges.subscribe((value) =>
            this.saveToStore(value)
        );
    }

    religionQuestionSubmit(): void {
        if (this.religionQuestionForm.valid) {
            this.saveToStore(this.religionQuestionForm.value);
            this.router
                .navigate([routesNames.SERVICE_QUESTION])
                .then((res: boolean) => {
                    if (res) {
                        this.analytics.event(
                            `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:next-click`,
                            {
                                event_category: routesNames.REASON_FOR_SENDING,
                                event_label: routesNames.APPLICATION_INFO,
                            }
                        );
                    }
                });
        } else {
            this.isSubmitted = true;
            this.religionQuestionForm.markAllAsTouched();
            this.displayMessage = CustomValidator.processMessages(
                this.religionQuestionForm
            );
            setTimeout(() => {
                const errorPopUp = document.getElementById("error-summary");
                errorPopUp.scrollIntoView();
                errorPopUp.querySelector("button").focus();
            });
        }
    }

    saveToStore(value: any): void {
        this.religionQuestionService.updateReligionQuestion(value);

        if (
            this.religionQuestionService.religionQuestion.religion ==
            Religion.Other
        ) {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(true)
            );
        } else {
            this.showOtherQuestion = new Observable((observer) =>
                observer.next(false)
            );
        }

        if (
            this.religionQuestionService.religionQuestion.religion ==
            Religion.Other
        ) {
            this.religionQuestionForm.controls["religionOther"].setValidators([
                Validators.required,
            ]);
        } else {
            this.religionQuestionForm.controls["religionOther"].setValidators(
                null
            );
            this.religionQuestionForm.controls["religionOther"].setErrors(null);
        }
    }

    goBack(): void {
        this.router
            .navigate([routesNames.PREFERENCE_QUESTION])
            .then((res: boolean) => {
                if (res) {
                    this.analytics.event(
                        `Evidence Submission Form | ${routesNames.REASON_FOR_SENDING}:back-click`,
                        {
                            event_category: routesNames.APPLICATION_INFO,
                            event_label: routesNames.EVIDENCE_SUBMISSION,
                        }
                    );
                }
            });
    }
}
