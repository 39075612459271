export enum ConditionSub {
    Vision = "Vision",
    Hearing = "Hearing",
    Mobility = "Mobility",

    Dexterity = "Dexterity",
    Learning = "Learning or understanding or concentrating",
    Memory = "Memory",
    Stamina = "Stamina or breathing or fatigue",
    Socially = "Socially or behaviourally ",
    None = "None",
    Other = "Other",
    PreferNotToSay = "Prefer not to say",
}
