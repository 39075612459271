import { Injectable } from "@angular/core";
import { Ethnicsub } from "./ethnic-sub.enum";

export interface EthnicSubQuestion {
    ethnicsub: string;
}

@Injectable({
    providedIn: "root",
})
export class EthnicSubQuestionService {
    private _ethnicSubQuestion: EthnicSubQuestion;

    constructor() {}

    get ethnicSubQuestion() {
        return this._ethnicSubQuestion;
    }

    updateEthnicSubQuestion(formObj: any): void {
        switch (formObj.ethnicsub) {
            case Ethnicsub.WhiteScottish: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.WhiteScottish;

                break;
            }

            case Ethnicsub.WhiteIrish: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.WhiteIrish;

                break;
            }
            case Ethnicsub.WhitePolish: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.WhitePolish;

                break;
            }
            case Ethnicsub.WhiteOtherBritish: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.WhiteOtherBritish;

                break;
            }
            case Ethnicsub.WhiteOther: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.WhiteOther;

                break;
            }
            case Ethnicsub.WhiteGypsyTraveller: {
                this._ethnicSubQuestion.ethnicsub =
                    Ethnicsub.WhiteGypsyTraveller;

                break;
            }
            case Ethnicsub.AsianIndian: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianIndian;

                break;
            }
            case Ethnicsub.AsianPakistani: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianPakistani;

                break;
            }
            case Ethnicsub.AsianBangladeshi: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianBangladeshi;

                break;
            }
            case Ethnicsub.AsianChinese: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianChinese;

                break;
            }
            case Ethnicsub.AsianChinese: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianChinese;

                break;
            }
            case Ethnicsub.AsianOther: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AsianOther;

                break;
            }
            case Ethnicsub.AfricanAfrican: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AfricanAfrican;

                break;
            }
            case Ethnicsub.AfricanOther: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.AfricanOther;

                break;
            }
            case Ethnicsub.CaribbeanCaribbean: {
                this._ethnicSubQuestion.ethnicsub =
                    Ethnicsub.CaribbeanCaribbean;

                break;
            }
            case Ethnicsub.CaribbeanBlack: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.CaribbeanBlack;

                break;
            }
            case Ethnicsub.CaribbeanOther: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.CaribbeanOther;

                break;
            }
            case Ethnicsub.OtherArab: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.OtherArab;

                break;
            }
            case Ethnicsub.OtherOther: {
                this._ethnicSubQuestion.ethnicsub = Ethnicsub.OtherOther;

                break;
            }

            default:
                this._ethnicSubQuestion.ethnicsub = "";
                break;
        }
    }

    initializeEthnicSubQuestion(): void {
        this._ethnicSubQuestion = {
            ethnicsub: "",
        };
    }
}
