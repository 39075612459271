import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EvidenceSubmissionComponent } from "./evidence-submission/evidence-submission.component";
import { ApplicationInfoComponent } from "./application-info/application-info.component";

import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { DocUploadConfirmationComponent } from "./doc-upload-confirmation/doc-upload-confirmation.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorSummaryComponent } from "./error-summary/error-summary.component";
import { ControlErrorComponent } from "./control-error/control-error.component";
import { DigitOnlyDirective } from "./shared/digit-only.directive";
import { CoreModule } from "./core/core.module";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { ReCaptchaDirective } from "./shared/recaptcha.directive";
import { ContextInterceptor } from "./core/interceptor/context.interceptor";
import { ReasonForSendingComponent } from "./reason-for-sending/reason-for-sending.component";
import { AgeQuestionComponent } from "./age-question/age-question.component";
import { EthnicQuestionComponent } from "./ethnic-question/ethnic-question.component";
import { EthnicSubQuestionComponent } from "./ethnic-sub-question/ethnic-sub-question.component";
import { EthnicOtherQuestionComponent } from "./ethnic-other-question/ethnic-other-question.component";
import { GenderQuestionComponent } from "./gender-question/gender-question.component";
import { TransQuestionComponent } from "./trans-question/trans-question.component";
import { ConditionQuestionComponent } from "./condition-question/condition-question.component";
import { ConditionSubQuestionComponent } from "./condition-sub-question/condition-sub-question.component";
import { PreferenceQuestionComponent } from "./preference-question/preference-question.component";
import { ReligionQuestionComponent } from "./religion-question/religion-question.component";
import { ServiceQuestionComponent } from "./service-question/service-question.component";
import { PersonRefComponent } from "./person-ref/person-ref.component";

@NgModule({
    declarations: [
        AppComponent,
        EvidenceSubmissionComponent,
        ApplicationInfoComponent,
        UploadDocumentsComponent,
        DocUploadConfirmationComponent,
        ErrorSummaryComponent,
        ControlErrorComponent,
        DigitOnlyDirective,
        FileUploadComponent,
        ReCaptchaDirective,
        ReasonForSendingComponent,
        AgeQuestionComponent,
        EthnicQuestionComponent,
        EthnicSubQuestionComponent,
        EthnicOtherQuestionComponent,
        GenderQuestionComponent,
        TransQuestionComponent,
        ConditionQuestionComponent,
        ConditionSubQuestionComponent,
        PreferenceQuestionComponent,
        ReligionQuestionComponent,
        ServiceQuestionComponent,
        PersonRefComponent,
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
    ],
    providers: [
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ContextInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
