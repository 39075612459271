import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-file-upload",
    templateUrl: "./file-upload.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    public onChange: Function;
    public onTouched: Function;

    public file: File | null = null;

    @Input() controlId: string;

    @HostListener("change", ["$event.target.files"]) emitFiles(
        event: FileList
    ) {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }

    constructor(private host: ElementRef<HTMLInputElement>) {}

    writeValue(value: null) {
        // clear file input
        this.host.nativeElement.value = "";
        this.file = null;
    }

    registerOnChange(fn: Function) {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function) {
        this.onTouched = fn;
    }

    public humanFileSize(bytes): string {
        const thresh = 314572800;
        if (Math.abs(bytes) < thresh) {
            return `file size: ${bytes} B`;
        }
        const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return `file size: ${bytes.toFixed(1)} ${units[u]}`;
    }
}
