import { Injectable } from "@angular/core";
import { ApplicationInfo } from "./application-info";

@Injectable({
    providedIn: "root",
})
export class ApplicationInfoService {
    private _applicationInfo: ApplicationInfo;

    constructor() {}

    get applicationInfo() {
        return this._applicationInfo;
    }

    updateApplicationInfo(values: ApplicationInfo): void {
        this._applicationInfo = values;
    }

    initializeApplicationInfo(): void {
        this._applicationInfo = {
            application: "",
            firstName: "",
            lastName: "",
            dob: {
                day: null,
                month: null,
                year: null,
            },
            phoneNumber: null,
        };
    }
}
