import { Injectable } from "@angular/core";
import { Reason } from "./reason.enum";

export interface ReasonForSending {
    reason: string;
    changeInCircumstances: boolean;
    benefitApplication: boolean;
    transferFromDwp: boolean;
  }

@Injectable({
    providedIn: "root"
})
  export class ReasonForSendingService {

    private _reasonForSending: ReasonForSending;

    constructor() { }

    get reasonForSending() {
        return this._reasonForSending;
    }

    updateReasonForSending(formObj: ReasonForSending): void {
        switch (formObj.reason) {
            case Reason.BenefitApplication: {
                this._reasonForSending.reason = Reason.BenefitApplication;
                this._reasonForSending.changeInCircumstances = false;
                this._reasonForSending.benefitApplication = true;
                this._reasonForSending.transferFromDwp = false;
                break;
            }
            case Reason.ChangeInCircumstances: {
                this._reasonForSending.reason = Reason.ChangeInCircumstances;
                this._reasonForSending.changeInCircumstances = true;
                this._reasonForSending.benefitApplication = false;
                this._reasonForSending.transferFromDwp = false;
                break;
            }
            case Reason.TransferFromDwp: {
                this._reasonForSending.reason = Reason.TransferFromDwp;
                this._reasonForSending.changeInCircumstances = false;
                this._reasonForSending.transferFromDwp = true;
                this._reasonForSending.benefitApplication = false;
                break;
            }
        }
    }

    initializeReasonForSending(): void {
        this._reasonForSending = {
            reason: "",
            changeInCircumstances: undefined,
            benefitApplication: undefined,
            transferFromDwp: undefined,
        };
      }
  }
