import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[digitOnly]"
})
export class DigitOnlyDirective {
  private decimalCounter = 0;

  @Input() decimal = false;
  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {

    let maxFieldLength: any = typeof (this.inputElement.getAttribute("maxlength") !== "undefined") ? this.inputElement.getAttribute("maxlength") : 21;
    maxFieldLength = maxFieldLength * 1;

    const allowedSpecialCharsASCII = ["Backspace", "Shift", "Tab", "Control", "Alt", "CapsLock", "End",
      "Home", "ArrowRight", "ArrowLeft", "Insert", "Delete", "ScrollLock", "Meta", "Left", "Right"];
    const isEraseKeyPressed = e.keyCode == 46 || e.keyCode == 8;
    const isNumKeyPressed = (e.keyCode >= 48 && e.keyCode <= 58) || (e.keyCode >= 96 && e.keyCode <= 105);

    const isAllowedChar = allowedSpecialCharsASCII.indexOf(e.key) > -1;
    const isTooLong = this.inputElement.value.length >= maxFieldLength && !isAllowedChar;

    if (!isEraseKeyPressed && ((!isAllowedChar && !isNumKeyPressed) || (isTooLong))) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData("text/plain");
    let pasted = false;
    if (!this.decimal) {
      pasted = document.execCommand(
        "insertText",
        false,
        pastedInput.replace(/[^0-9]/g, "")
      );
    } else if (this.isValidDecimal(pastedInput)) {
      pasted = document.execCommand(
        "insertText",
        false,
        pastedInput.replace(/[^0-9.]/g, "")
      );
    }
    if (pasted) {
      event.preventDefault();
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(pastedInput);
        document.execCommand("paste");
      }
    }
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData("text");
    this.inputElement.focus();

    let pasted = false;
    if (!this.decimal) {
      pasted = document.execCommand(
        "insertText",
        false,
        textData.replace(/[^0-9]/g, "")
      );
    } else if (this.isValidDecimal(textData)) {
      pasted = document.execCommand(
        "insertText",
        false,
        textData.replace(/[^0-9.]/g, "")
      );
    }
    if (pasted) {
      event.preventDefault();
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textData);
        document.execCommand("paste");
      }
    }
  }

  isValidDecimal(str: string): boolean {
    return str.split(".").length <= 2;
  }
}
