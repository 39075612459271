import { Injectable } from "@angular/core";
import { Condition } from "./condition.enum";

export interface ConditionQuestion {
    condition: string;
}

@Injectable({
    providedIn: "root",
})
export class ConditionQuestionService {
    private _conditionQuestion: ConditionQuestion;

    constructor() {}

    get conditionQuestion() {
        return this._conditionQuestion;
    }

    updateConditionQuestion(formObj: ConditionQuestion): void {
        switch (formObj.condition) {
            case Condition.Yes: {
                this._conditionQuestion.condition = Condition.Yes;

                break;
            }
            case Condition.No: {
                this._conditionQuestion.condition = Condition.No;

                break;
            }
            case Condition.PreferNotToSay: {
                this._conditionQuestion.condition = Condition.PreferNotToSay;

                break;
            }
        }
    }

    initializeConditionQuestion(): void {
        this._conditionQuestion = {
            condition: "",
        };
    }
}
